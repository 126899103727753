import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  DefaultContext,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import {initializeApp} from "firebase/app";
import {Auth, getAuth, User} from "firebase/auth";
import {setContext} from "@apollo/client/link/context";
import {createClient} from "graphql-ws";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {getMainDefinition} from "@apollo/client/utilities";

type AppConfig = {
  AuthEnabled: boolean;
  EphemeralEnvironment: boolean;
  Auth: Auth | undefined;
  // User contains info on the logged-in user. Undefined if the user is not
  // logged in. In ephemeral environments, User will be an anonymous user
  // generated by Firebase.
  User: User | undefined;
  AgentsPollIntervalMillis?: number;
  // APIBaseURL is the URL to use for the server API. If missing, assume that
  // the server is running on the same host as the webapp.
  APIBaseURL?: URL;

  AppxURL?: string;

  wsLink?: ApolloLink;
};

export const AppConfigContext = createContext<AppConfig>({
  AuthEnabled: false,
  EphemeralEnvironment: false,
  Auth: undefined,
  User: undefined,
  AgentsPollIntervalMillis: undefined,
  AppxURL: undefined,
});

// computeEphemeralEnvironmentAndAPIBaseURL takes the apiURL from the config and
// looks at window.location. Based on this, it decides whether an ephemeral
// environment is being accessed. The API URL is also returned; for ephemeral
// environments, the API URL includes the name of the environment.
function computeEphemeralEnvironmentAndAPIBaseURL(apiURL: string | undefined): {
  ephemeralEnvironment: boolean;
  apiBaseURL: URL | undefined;
} {
  if (!apiURL) {
    // If no apiURL is present in the config, the app must be running locally.
    // In this case, "localhost" is not an ephemeral environment, and
    // demo.localhost is.
    const origin = new URL(window.location.origin);
    const originComponents = origin.hostname.split(".");
    return {
      ephemeralEnvironment: originComponents.length == 2,
      apiBaseURL: undefined,
    };
  }

  // The observation here is that ephemeral environments have the form
  // <ephemeral_env>.<subdomain>.side-eye.io. If there is an ephemeral
  // environment, then we'll use the same subdomain of the API base URL
  // for making requests.
  const apiBaseURL = new URL(apiURL);
  const origin = new URL(window.location.origin);
  const originComponents = origin.hostname.split(".");
  const ephemeralEnvironment = originComponents.length === 4;
  if (ephemeralEnvironment) {
    const subdomain = originComponents[0];
    apiBaseURL.hostname = subdomain + "." + apiBaseURL.hostname;
  }
  return {ephemeralEnvironment, apiBaseURL};
}

// AppConfigProvider is a context provider that provides the app's
// configuration. It also provides the Apollo client, since the client depends
// on the auth config.
export const AppConfigProvider: React.FunctionComponent<
  PropsWithChildren<unknown>
> = ({children}) => {
  const [config, setConfig] = useState<AppConfig | undefined>(undefined);
  const [err, setErr] = useState<Error | undefined>(undefined);
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      // Read the app config.

      // NOTE: This type must be kept in sync with the copy in vite.config.ts.
      type AppConfig = {
        ApiURL?: string;
        AgentsPollIntervalMillis?: number;
        AppxURL?: string;
      };

      const appConfigData = await fetch("/app-config.json").then((res) => {
        if (!res.ok) {
          throw new Error(`failed to fetch app config: ${res.statusText}`);
        }
        return res.json();
      });
      const baseConfig = appConfigData as AppConfig;

      const {apiBaseURL, ephemeralEnvironment} =
        computeEphemeralEnvironmentAndAPIBaseURL(baseConfig.ApiURL);

      // Read the auth config.
      const authConfigData = await fetch(
        getApiUrlForPath(apiBaseURL, "/auth-config"),
      ).then((res) => {
        if (!res.ok) {
          throw new Error(`failed to fetch app config: ${res.statusText}`);
        }
        return res.json();
      });
      // Combine the app config and the auth config.
      type CombinedConfig = {
        // Fields from the app config.
        AgentsPollIntervalMillis?: number;
        APIUrl?: string;
        AppxURL?: string;

        // Fields from the auth config.
        AuthEnabled: boolean;
        FirebaseConfig?: {
          apiKey: string;
          authDomain: string;
          projectId: string;
          storageBucket: string;
          messagingSenderId?: string;
          appId: string;
        };
      };
      const cfg = authConfigData as CombinedConfig;
      cfg.APIUrl = baseConfig.ApiURL;
      cfg.AgentsPollIntervalMillis = baseConfig.AgentsPollIntervalMillis;
      cfg.AppxURL = baseConfig.AppxURL;

      let auth: Auth | undefined = undefined;

      // Create an ApolloClient. The client captures a reference to the
      // mutable auth object in the authLink, and passes along the identity of
      // the user when making requests to the server.

      const httpLink = createHttpLink({
        uri: getApiUrlForPath(apiBaseURL, "/graphql"),
      });
      const wsLink = new GraphQLWsLink(
        createClient({
          url: getApiUrlForPath(apiBaseURL, "/graphql"),
          connectionParams: async () => {
            const token = await getAuthToken(auth);
            return {
              Authorization: `Bearer ${token}`,
            };
          },
        }),
      );

      const authLink = setContext(
        // Return the headers to the context so httpLink can read them.
        async (_req, context: DefaultContext) => {
          const headers = await setAuthHeaders(
            // NOTE(andrei): I haven't found a type for context.headers. In
            // practice, we seem to get undefined.
            (context.headers ?? {}) as Record<string, string>,
            auth,
          );
          return {...context, headers};
        },
      );
      const httpLinkWithAuth = authLink.concat(httpLink);

      const splitLink = split(
        ({query}) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLinkWithAuth,
      );

      const client = new ApolloClient({
        link: splitLink,
        devtools: {
          enabled: true,
        },
        cache: new InMemoryCache({
          typePolicies: {
            OrgInfo: {
              keyFields: ["orgName"],
            },
            SnapshotSpec: {
              // TODO: SnapshotSpec's are singletons; they don't have IDs. I think
              // setting keyFields like below is supposed to tell Apollo to treat
              // a spec as such and update it in the cache with mutation results,
              // but I failed to get it to work. Instead, the spec got a dummy ID.
              //
              // keyFields: [],

              // Tell Apollo how to merge array fields. Without this, it warns
              // that data might be wrongly over-written.
              fields: {
                links: {
                  merge: (existing, incoming) => incoming,
                },
                modules: {
                  merge: (existing, incoming) => incoming,
                },
                programs: {
                  merge: (existing, incoming) => incoming,
                },
                tables: {
                  merge: (existing, incoming) => incoming,
                },
              },
            },
          },
        }),
        // Disable cache for development, to make debugging easier.
        //
        // defaultOptions: {
        //   watchQuery: {
        //     fetchPolicy: 'no-cache',
        //   },
        //   query: {
        //     fetchPolicy: 'no-cache',
        //   },
        // }
      });

      if (cfg.AuthEnabled) {
        // Initialize Firebase.
        const app = initializeApp(cfg.FirebaseConfig!);
        auth = getAuth(app);
        // When the auth state settles, update the context value.
        //
        // NOTE: There will be an initial onAuthStateChanged call even if the
        // user is not signed in.
        auth.onAuthStateChanged((user: User | null) => {
          setConfig({
            AuthEnabled: cfg.AuthEnabled,
            EphemeralEnvironment: ephemeralEnvironment,
            Auth: auth,
            AgentsPollIntervalMillis: cfg.AgentsPollIntervalMillis,
            // Turn null into undefined.
            User: user ?? undefined,
            APIBaseURL: apiBaseURL,
            AppxURL: cfg.AppxURL,
            wsLink: wsLink,
          });
        });
        // When the user's token changes, update the context value. The token
        // changes when a superuser changes the organization it impersonates.
        auth.onIdTokenChanged((user: User | null) => {
          setConfig({
            AuthEnabled: cfg.AuthEnabled,
            EphemeralEnvironment: ephemeralEnvironment,
            Auth: auth,
            AgentsPollIntervalMillis: cfg.AgentsPollIntervalMillis,
            // Turn null into undefined.
            User: user ?? undefined,
            APIBaseURL: apiBaseURL,
            AppxURL: cfg.AppxURL,
            wsLink: wsLink,
          });
        });
      } else {
        setConfig({
          AuthEnabled: cfg.AuthEnabled,
          EphemeralEnvironment: ephemeralEnvironment,
          Auth: auth,
          AgentsPollIntervalMillis: cfg.AgentsPollIntervalMillis,
          User: undefined,
          APIBaseURL: apiBaseURL,
          AppxURL: cfg.AppxURL,
          wsLink: wsLink,
        });
      }

      setClient(client);
    };

    fetchData().catch((err) => {
      console.error(err);
      setErr(err);
    });
  }, []);

  if (err !== undefined) {
    return <>Failed to initialize app: {err.message}</>;
  }
  // Wait until everything is initialized.
  if (config == undefined || client == undefined) {
    return <>initializing app...</>;
  }

  return (
    <AppConfigContext.Provider value={config}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </AppConfigContext.Provider>
  );
};

// Get the URL to use to make a request to the API for a given path.
export function getApiUrlForPath(
  apiBaseURL: URL | undefined,
  path: string,
): string {
  return apiBaseURL ? new URL(path, apiBaseURL.toString()).toString() : path;
}

export function getAppXUrl(cfg: AppConfig): string {
  if (cfg.AppxURL == undefined) {
    throw new Error("AppXURL missing in AppConfig");
  }
  return cfg.AppxURL;
}

export async function getAuthToken(auth?: Auth): Promise<string | undefined> {
  if (auth && auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return token;
  } else {
    return undefined;
  }
}

export async function setAuthHeaders(
  headers: Record<string, string>,
  auth?: Auth,
): Promise<Record<string, string>> {
  const token = await getAuthToken(auth);
  if (token != undefined) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  } else {
    return headers;
  }
}
