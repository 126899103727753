import {IconDownload} from "@components/icons/IconDownload";
import {AuthenticatedDownloadLink} from "@components/util";
import {Button, Tooltip} from "@mui/material";

type Props = {
  url: string;
  tooltip: string;
  fallbackFilename: string;
};

export function DownloadDatabaseButton({
  url,
  fallbackFilename,
  tooltip,
}: Props) {
  return (
    <Tooltip title={tooltip}>
      <Button variant="outlined" color="info">
        <AuthenticatedDownloadLink
          url={url}
          fallbackFilename={fallbackFilename}
        >
          <IconDownload />
        </AuthenticatedDownloadLink>
      </Button>
    </Tooltip>
  );
}
