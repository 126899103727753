import {gql} from "../../__generated__";

gql(/* GraphQL */ `
  fragment FullLabelRule on LabelRule {
    ID
    label
    value
    predicatesConjunction {
      id
      label
      valueRegex
    }
  }
`);
export const GET_LABEL_RULES = gql(/*GraphQL */ `
    query GetLabelRules {
        getLabelRules {
            ...FullLabelRule
        }
    }
`);

export const ADD_LABEL_RULE = gql(/*GraphQL */ `
    mutation AddLabelRule($input: LabelRuleInput!) {
        addLabelRule(input: $input) {
            ...FullLabelRule
        }
    }
`);

export const UPDATE_LABEL_RULE = gql(/*GraphQL */ `
    mutation UpdateLabelRule($id: ID!, $input: LabelRuleInput!) {
        updateLabelRule(id: $id, input: $input) {
            ...FullLabelRule
        }
    }
`);

export const DELETE_LABEL_RULE = gql(/*GraphQL */ `
    mutation DeleteLabelRule($id: ID!) {
        deleteLabelRule(id: $id) {
            ...FullLabelRule
        }
    }
`);

export const GET_PPROF_RULES = gql(/*GraphQL */ `
  query GetPprofRules {
    getPprofRules {
      ID
      program
      port
    }
  }
`);

export const ADD_PPROF_RULE = gql(/*GraphQL */ `
  mutation AddPprofRule($program: String!, $port: Int!) {
    addPprofRule(program: $program, port: $port) {
      ID
      program
      port
    }
  }
`);

export const DELETE_PPROF_RULE = gql(/*GraphQL */ `
  mutation DeletePprofRule($id: ID!) {
    deletePprofRule(id: $id) {
      ID
      program
      port
    }
  }
`);

export const GET_ALL_PROCESSES = gql(/* GraphQL */ `
  query GetAllProcesses {
    getAgentReports(onlyMatching: false) {
      Reports {
        Environment
        Hostname
        Processes {
          Labels {
            Label
            Value
          }
        }
      }
    }
  }
`);
