import React from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {Link as ReactLink} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "@apollo/client";
import {DELETE_RECORDING, GET_RECORDINGS} from "./gqlHelper.ts";
import dayjs from "dayjs";
import {Avatar} from "@components/Avatar.tsx";
import {toastError} from "src/components/tables/util";
import {LogTimelineItem, SnapshotInfoMinimal} from "./@types.ts";
import {GetRecordingsQuery} from "@graphql/graphql";

type Props = {
  recording: GetRecordingsQuery["getRecordings"][number];
};

export const RecordingTimelineItem = ({recording}: Props) => {
  const [deleteRecording] = useMutation(DELETE_RECORDING, {
    refetchQueries: [GET_RECORDINGS],
  });

  let snapshot: SnapshotInfoMinimal | undefined;
  let log: LogTimelineItem | undefined;
  let programs = new Set<string>();
  if ((recording.snapshots?.length ?? 0) > 0) {
    if (recording.snapshots!.length > 1) {
      throw new Error("recording with more than one snapshot not supported");
    }
    const snap = recording.snapshots![0];
    programs = new Set(snap.processSnapshots!.map((ps) => ps.process.program));
    const firstTimeStr = snap.processSnapshots![0].captureTime;
    let captureTime = firstTimeStr ? Date.parse(firstTimeStr) : 0;
    for (const ps of snap.processSnapshots!) {
      const timestamp = Date.parse(ps.captureTime);
      if (timestamp < captureTime) {
        captureTime = timestamp;
      }
    }
    const si: SnapshotInfoMinimal = {
      id: snap.id,
      recordingID: snap.recordingID,
      captureTime: captureTime,
      environment: recording.environment ?? "",
      user: recording.user,
      programs: Array.from(
        new Set(snap.processSnapshots!.map((ps) => ps.process.program)),
      ),
    };
    snapshot = si;
  }
  if ((recording.logs?.length ?? 0) > 0) {
    if (recording.logs!.length > 1) {
      throw new Error("recording with more than one log not supported");
    }
    const firstLog = recording.logs![0];

    // Ignore logs that exist only for the purpose of the CPU profile.
    if (!firstLog.containsOnlyCpuProfileSamples) {
      // Add all the log's programs to our set of programs.
      firstLog.processes
        .map((p) => p.program)
        .forEach((program) => programs.add(program));

      const li: LogTimelineItem = {
        id: firstLog.id,
        recordingID: firstLog.recordingID,
        startTime: firstLog.startTime,
        environment: recording.environment ?? "",
        programs: Array.from(new Set(firstLog.processes.map((p) => p.program))),
        user: recording.user,
      };
      log = li;
    }
  }
  const hasExecutionTraces = recording.executionTraces?.length ?? 0 > 0;
  const firstCPUProfile = recording.cpuProfiles
    ? recording.cpuProfiles.length > 0
      ? recording.cpuProfiles[0]
      : undefined
    : undefined;

  let name = "";
  if (recording.environment) {
    name += `${recording.environment}`;
  }
  name += " (" + Array.from(programs).join(", ") + ")";

  return (
    <Card>
      <CardContent
        component={Stack}
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          <Typography variant="body2" color="primary.light" sx={{minWidth: 50}}>
            {recording.id}
          </Typography>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar photoURL={recording.user?.photoURL ?? undefined} />
            <Typography variant="body2" color="textSecondary" width={200}>
              {recording.user?.name ?? "<user unknown>"}
            </Typography>
            <Box>{name}</Box>
            {snapshot && (
              <Link component={ReactLink} to={`/snapshots/` + snapshot.id}>
                snapshot
              </Link>
            )}
            {log && (
              <Link component={ReactLink} to={`/logs/` + log.id}>
                event log
              </Link>
            )}
            {hasExecutionTraces ? <Box>execution traces</Box> : <></>}
            {firstCPUProfile ? (
              <Link
                component={ReactLink}
                to={`/cpuprofile/` + firstCPUProfile.id}
              >
                CPU profile
              </Link>
            ) : (
              <></>
            )}
          </Stack>

          <Typography variant="body2" color="textSecondary" width={300}>
            {dayjs(recording.startTime).format("dddd, h:mm:ss A z")}
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            deleteRecording({
              variables: {id: recording.id},
            }).catch((e) => toastError(e, "failed to delete recording"));
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};
