import {
  ChartsAxisContentProps,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  DefaultChartsAxisTooltipContent,
  LineHighlightPlot,
  LinePlot,
  LineSeriesType,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import React from "react";
import {AxisValueFormatterContext} from "@mui/x-charts/internals";
import dayjs from "dayjs";

interface seriesData {
  functionQualifiedName: string;
  // null for series corresponding to function probes (as opposed to line
  // probes).
  lineNumber: number | null;
  countsPerSecond: number[];
}

export default function EventFrequencyChart({
  series,
  xAxisData,
  startTime,
}: {
  series: seriesData[];
  xAxisData: number[];
  startTime: dayjs.Dayjs;
}): React.JSX.Element {
  const muiSeries: LineSeriesType[] = series.map((s) => {
    let suffix = "";
    if (s.lineNumber != null) {
      suffix = `:${s.lineNumber}`;
    }
    return {
      type: "line" as const,
      curve: "linear",
      data: s.countsPerSecond,
      label: s.functionQualifiedName + suffix,
      showMark: true,
    };
  });

  return (
    <ResponsiveChartContainer
      series={muiSeries}
      xAxis={[
        {
          scaleType: "linear",
          data: xAxisData,
          id: "x-axis-id",
          tickSize: 6,
          // Don't add fractional ticks.
          tickMinStep: 1,
          valueFormatter: (
            secondsValue: number,
            ctx: AxisValueFormatterContext,
          ): string => {
            // If we're formatting for the tooltip, make it fancy.
            if (ctx.location == "tooltip") {
              const timestamp = startTime
                .add(secondsValue, "second")
                .format("HH:mm:ss");
              return `${timestamp} (${secondsValue} seconds after log start)`;
            }
            return secondsValue.toString();
          },
        },
      ]}
      yAxis={[{scaleType: "linear", min: 0}]}
    >
      {/*Uncomment this to show dots for each point in the series. If there are
      over 50 dots, it becomes overwhelming.*/}
      {/*<MarkPlot />*/}

      {/*Render the line connecting the dots.*/}
      <LinePlot />
      <ChartsXAxis
        label="Time (seconds since log start)"
        position="bottom"
        axisId="x-axis-id"
      />
      <ChartsYAxis label="Events per second" position="left" />

      {/*Show the series' labels (i.e. the function names).*/}
      <ChartsLegend slotProps={{legend: {labelStyle: {fontSize: 12}}}} />

      {/*<ChartsOverlay />*/}

      {/*Highlight the point matching the mouse cursor.*/}
      <LineHighlightPlot />
      <ChartsTooltip
        // Set this to show the tooltip when hovering over a point, not anywhere
        // on the graph. If we do that, we might have to change the slot we use
        // for the tooltip too.
        // trigger={"item"}

        // Control the tooltip.
        slots={{axisContent: AxisTooltip}}
      />
    </ResponsiveChartContainer>
  );
}

function AxisTooltip(props: ChartsAxisContentProps): React.JSX.Element {
  // Delegate to the charts default. Having this function here serves as a
  // placeholder for future customization.
  return <DefaultChartsAxisTooltipContent {...props} />;
}
