import {
  confirmationDialogInfo,
  warningMessageForTableValidationFail,
} from "src/components/tables/util.tsx";
import {
  CollectExprSpec,
  CollectExprSpecInput,
  ColumnSpec,
  ColumnType,
  FunctionName,
  FunctionSnapshotSpec,
  FunctionStartEventSpec,
} from "src/__generated__/graphql.ts";
import {
  addOrUpdateFunctionSpec,
  VALIDATE_FUNCTION_SPEC_DELETION,
} from "src/util/queries.tsx";
import {ApolloClient} from "@apollo/client";
import React, {ReactNode} from "react";
import {Typography} from "@mui/material";
import {funcOrMethodNameWithShortPkg} from "@util/util.ts";

export type specType = "snapshot" | "event";

export type Spec<T extends specType> = T extends "snapshot"
  ? FunctionSnapshotSpec
  : T extends "event"
    ? FunctionStartEventSpec
    : never;

export interface FunctionSpecEditorInterface {
  onColumnNameUpdated: (expr: string, newName: string) => Promise<boolean>;
  onColumnHiddenUpdated: (expr: string, newHidden: boolean) => Promise<boolean>;
  onExtraColumnDeleted: (columnName: string) => Promise<boolean>;
  onExtraColumnAdded: (
    colName: string,
    colExpr: string,
    colType: ColumnType,
    hidden: boolean,
  ) => Promise<boolean>;
  onExtraColumnEdited: (
    origName: string,
    newName: string,
    expr: string,
    colType: ColumnType,
    hidden: boolean,
  ) => Promise<boolean>;
  onTableNameUpdated: (newTableName: string) => Promise<boolean>;
  onExpressionCollectChange: (
    expr: string,
    checked: boolean,
  ) => Promise<boolean>;
  onExpressionDeleted: (expr: string) => Promise<boolean>;
  onAddSnapshotSpec: () => Promise<boolean>;
  onSnapshotSpecDelete: () => Promise<boolean>;
  onAddFunctionStartEvent: () => Promise<boolean>;
  onEventSpecDelete: () => Promise<boolean>;
  onEventMessageEdited: (newMessage: string) => Promise<boolean>;
}

// FunctionSpecEditor provides functionality for editing either the snapshot
// spec or the event spec of a function.
export class FunctionSpecEditor<T extends specType>
  implements FunctionSpecEditorInterface
{
  specType: T;
  funcName: FunctionName;
  spec: Spec<T> | undefined;
  showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>;
  client: ApolloClient<unknown>;

  constructor(
    specType: T,
    funcName: FunctionName,
    spec: Spec<T> | undefined,
    showConfirmationDialog: (_: confirmationDialogInfo) => Promise<boolean>,
    client: ApolloClient<unknown>,
  ) {
    this.specType = specType;
    this.funcName = funcName;
    this.spec = spec;
    this.showConfirmationDialog = showConfirmationDialog;
    this.client = client;
  }

  onColumnNameUpdated = async (
    expr: string,
    newName: string,
  ): Promise<boolean> => {
    const exprs: CollectExprSpec[] = this.spec?.collectExprs ?? [];
    const newExprs = exprs.map((colExpr) => {
      if (colExpr.expr == expr) {
        return {
          expr: colExpr.expr,
          column: {...colExpr.column, name: newName},
        };
      }
      return colExpr;
    });

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              collectExprs: newExprs,
            },
          }
        : {
            functionStartEvent: {
              collectExprs: newExprs,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onColumnHiddenUpdated = async (
    expr: string,
    newHidden: boolean,
  ): Promise<boolean> => {
    const exprs: CollectExprSpec[] = this.spec?.collectExprs ?? [];
    const newExprs = exprs.map((colExpr) => {
      if (colExpr.expr == expr) {
        return {
          expr: colExpr.expr,
          column: {...colExpr.column, hidden: newHidden},
        };
      }
      return colExpr;
    });

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              collectExprs: newExprs,
            },
          }
        : {
            functionStartEvent: {
              collectExprs: newExprs,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onExtraColumnDeleted = async (columnName: string): Promise<boolean> => {
    const cols: ColumnSpec[] = this.spec?.extraColumns ?? [];
    const newCols = cols.filter((col) => col.name != columnName);

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              extraColumns: newCols,
            },
          }
        : {
            functionStartEvent: {
              extraColumns: newCols,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  // onExtraColumnAdded adds one column to the function table's `extraColumns` list.
  onExtraColumnAdded = async (
    colName: string,
    colExpr: string,
    colType: ColumnType,
    hidden: boolean,
  ): Promise<boolean> => {
    const cols: ColumnSpec[] = this.spec?.extraColumns ?? [];
    const newCols = [
      ...cols,
      {
        name: colName,
        expr: colExpr,
        type: colType,
        hidden,
      },
    ];

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              extraColumns: newCols,
            },
          }
        : {
            functionStartEvent: {
              extraColumns: newCols,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onExtraColumnEdited = async (
    origName: string,
    newName: string,
    expr: string,
    colType: ColumnType,
    hidden: boolean,
  ): Promise<boolean> => {
    const cols: ColumnSpec[] = this.spec?.extraColumns ?? [];

    const newCols = cols.map((col) => {
      if (col.name == origName) {
        return {...col, name: newName, expr: expr, type: colType, hidden};
      }
      return col;
    });

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              extraColumns: newCols,
            },
          }
        : {
            functionStartEvent: {
              extraColumns: newCols,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onTableNameUpdated = async (newTableName: string): Promise<boolean> => {
    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              tableName: newTableName,
            },
          }
        : {
            functionStartEvent: {
              tableName: newTableName,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  // handler for a tree node getting checked or unchecked.
  onExpressionCollectChange = async (
    expr: string,
    checked: boolean,
  ): Promise<boolean> => {
    const exprs: CollectExprSpec[] = this.spec?.collectExprs ?? [];

    let newExprs: CollectExprSpecInput[];
    if (checked) {
      newExprs = [
        ...exprs,
        {
          expr,
          column: {
            hidden: false,
          },
        },
      ];
    } else {
      // TODO: when editing the snapshot spec, check if this expression is being
      // used by the friendly name and show the dialog to ask the user to
      // confirm. If confirmed, delete the friendly name spec. The CollectSpec
      // component does this before deleting whole frame spec; I'm not sure how
      // best to plumb dialog to here.
      newExprs = exprs.filter((oldExpr) => oldExpr.expr != expr);
    }

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              collectExprs: newExprs,
            },
          }
        : {
            functionStartEvent: {
              collectExprs: newExprs,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  // onExpressionDeleted is called when an expression that is no longer matching
  // the binary is deleted. In contrast to onExpressionCollectChange,
  // onExpressionDeleted deletes that expression and all the children (i.e. all
  // the expression that have "expr." as a prefix).
  onExpressionDeleted = async (expr: string): Promise<boolean> => {
    const prefix = expr + ".";

    const exprs = this.spec?.collectExprs ?? [];

    // Remove all expressions from the spec that start with `expr`.
    const newExprs: CollectExprSpecInput[] = exprs.filter((e) => {
      const fieldExpr = e.expr;
      return fieldExpr != expr && !fieldExpr.startsWith(prefix);
    });

    const vars =
      this.specType == "snapshot"
        ? {
            snapshotSpec: {
              collectExprs: newExprs,
            },
          }
        : {
            functionStartEvent: {
              collectExprs: newExprs,
            },
          };

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          ...vars,
        },
        undefined /* showConfirmationDialog */,
      )) != undefined
    );
  };

  onAddSnapshotSpec = async (): Promise<boolean> => {
    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          snapshotSpec: {},
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onSnapshotSpecDelete = async (): Promise<boolean> => {
    const spec = this.spec;
    if (spec == undefined) {
      throw "onSnapshotSpecDelete unexpected call on function without snapshot spec";
    }

    // If the function spec contains a non-empty snapshot spec, ask for confirmation.
    const hasSnapshotSpec: boolean =
      spec.collectExprs.length > 0 || spec.extraColumns.length > 0;

    // Validate the deletion. If validation fails, ask for confirmation.
    const {data: validationQueryRes} = await this.client.query({
      query: VALIDATE_FUNCTION_SPEC_DELETION,
      variables: {funcQualifiedName: this.funcName.QualifiedName},
      fetchPolicy: "no-cache",
    });
    const validationResult = validationQueryRes.validateFunctionSpecDeletion;
    const validationFailed =
      validationResult.newlyFailingTables.length > 0 ||
      validationResult.newlyFailingLinks.length > 0;
    let validationFailedMsg: ReactNode | undefined = undefined;
    if (validationFailed) {
      validationFailedMsg = warningMessageForTableValidationFail(
        "", // tableError
        validationResult.newlyFailingTables,
        validationResult.newlyFailingLinks,
      );
    }

    // If this frames table deletion failed validation, or even if it didn't but
    // the snapshot spec is not empty, show a confirmation dialog asking the
    // user to confirm.
    if (validationFailedMsg || hasSnapshotSpec) {
      const dialogContent: React.JSX.Element = (
        <>
          Are you sure you want to stop collecting data for function{" "}
          {this.funcName.QualifiedName} from snapshots?
          <br />
          <Typography>
            {warningMessageForTableValidationFail(
              "", // tableError
              validationResult.newlyFailingTables,
              validationResult.newlyFailingLinks,
            )}
          </Typography>
        </>
      );
      const confirmed: boolean = await this.showConfirmationDialog({
        title: "Confirm function deletion",
        content: dialogContent,
      });
      if (!confirmed) {
        // User changed their mind.
        return false;
      }
    }

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          deleteSnapshotSpec: true,
        },
        undefined /* showConfirmationDialog */,
      )) != undefined
    );
  };

  onAddFunctionStartEvent = async (): Promise<boolean> => {
    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          functionStartEvent: {
            // Default the message to the function name.
            message: funcOrMethodNameWithShortPkg(this.funcName),
          },
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };

  onEventSpecDelete = async () => {
    const spec = this.spec;
    if (spec == undefined) {
      throw "onEventSpecDelete unexpected call on function without snapshot spec";
    }

    // If the function spec contains a non-empty event spec, ask for confirmation.
    const hasEventSpec: boolean =
      spec.collectExprs.length > 0 || spec.extraColumns.length > 0;
    if (hasEventSpec) {
      const dialogContent = (
        <>
          Are you sure you want to stop generating events for function{" "}
          {this.funcName.QualifiedName}?
        </>
      );
      const confirmed: boolean = await this.showConfirmationDialog({
        title: "Confirm event deletion",
        content: dialogContent,
      });
      if (!confirmed) {
        // User changed their mind.
        return false;
      }
    }

    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          deleteFunctionStartEvent: true,
        },
        undefined /* showConfirmationDialog */,
      )) != undefined
    );
  };

  onEventMessageEdited = async (newMessage: string): Promise<boolean> => {
    return (
      (await addOrUpdateFunctionSpec(
        this.client,
        {
          funcQualifiedName: this.funcName.QualifiedName,
          functionStartEvent: {
            message: newMessage,
          },
        },
        this.showConfirmationDialog,
      )) != undefined
    );
  };
}
