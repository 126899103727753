import CropFreeIcon from "@mui/icons-material/CropFree";
import LinkIcon from "@mui/icons-material/Link";
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import {useProcessResolver} from "@providers/processResolverProvider";
import {useRecording} from "@providers/recordingProvider";
import JsonView from "@uiw/react-json-view";
import {parse as parseLoseless} from "lossless-json";
import React, {useContext, useRef, useState} from "react";
import {Link as ReactLink} from "react-router-dom";
import {tablesURLController} from "src/components/tables/util.tsx";
import {
  SnapshotState,
  useSnapshotState,
} from "src/providers/snapshot-state.tsx";
import {SpecContext} from "src/providers/spec-provider.tsx";
import {resolvedLinksInfo} from "src/util/links.ts";
import {tableNameToTableReference} from "src/util/spec.ts";
import {parseNumber} from "src/util/types.ts";
import {
  NANOS_PER_MILLI_BIGINT,
  ProcessInfo,
  formatDurationNanos,
  parseGoroutineID,
} from "src/util/util.ts";
import {ColumnType} from "../__generated__/graphql.ts";
import GoroutineLink, {ProcessLink} from "../components/goroutine-link.tsx";
import {AppConfigContext, getAppXUrl} from "../providers/app-config-provider";
import {JSONViewTheme} from "../theme/JSONViewTheme.ts";

export type CellProps = {
  value: string | null;
  // name represents some description of what the value represents. It is used
  // as the title of the modal dialog.
  name: string;
  // The data type of the value. If type == ColumnType.String, the value is
  // interpreted as either a string containing a JSON document, or a primitive
  // string (depending on whether the value is valid JSON).
  type: ColumnType;

  // onExpand, if set, is called when the user expands or collapses a JSON field.
  onExpand?: (expanded: boolean) => void;

  // links is a summary of the rows in other tables that this cell is linked to.
  links: resolvedLinksInfo[];
  controller: tablesURLController;
  // processInfo is the process information for the process that the row
  // containing this cell refers to. undefined if not known or the row does not
  // belong to a particular process.
  processInfo: ProcessInfo | undefined;

  // snapshotState, if set, is used to generate URLs for goroutine links. If not
  // set, the goroutine ID columns will not be rendered as links.
  snapshotState: SnapshotState | undefined;
};

type CellContentProps = Omit<CellProps, "controller">;

// CellContent deals with rendering the content of a table cell - i.e. a single
// value. Depending on the type of the value, it is rendered differently (e.g.
// as a JsonView for a JSON document). CellContent is used inside a table, but
// it's also used by the full-screen modal.
function CellContent(props: CellContentProps): React.JSX.Element {
  const appConfig = useContext(AppConfigContext);
  const processResolver = useProcessResolver();
  const recordingContext = useRecording();
  const val = props.value;
  if (val == null) return <span className="text-muted">NULL</span>;

  switch (props.type) {
    case ColumnType.TimestampNs: {
      const check = parseInt(val);
      if (isNaN(check)) {
        return renderPossiblyJSONValue(val, props.onExpand);
      }
      const v: bigint = BigInt(val);
      const millis = v / NANOS_PER_MILLI_BIGINT;
      const ts = new Date(Number(millis));
      const deltaToSnapshotNanos =
        props.processInfo != undefined
          ? props.processInfo.CaptureTimeNanos - v
          : undefined;
      return (
        <>
          {ts.toISOString()}
          {deltaToSnapshotNanos != undefined &&
            ` (${formatDurationNanos(Number(deltaToSnapshotNanos))} ago)`}
        </>
      );
    }
    case ColumnType.DurationNs:
      return <>{formatDurationNanos(parseInt(val))}</>;
    case ColumnType.String:
      return renderPossiblyJSONValue(val, props.onExpand);
    case ColumnType.ProcessId:
      return <ProcessLink processID={parseInt(val)} />;
    case ColumnType.GoroutineId: {
      const gid = parseGoroutineID(val);

      // When rendering goroutine IDs, we want to link them to something. What
      // we link to depends on whether we're in the context of a snapshot or a
      // log.
      let clickURL: string | undefined;
      if (props.snapshotState != undefined) {
        // Navigate to the goroutine's stack.
        clickURL = props.snapshotState.goroutineURL(gid.ProcessID, gid.ID);
      } else if (
        recordingContext != undefined &&
        processResolver.processes.length > 0
      ) {
        // Navigate to the goroutine's execution trace.
        //
        // TODO: Only show the link if an execution trace has been collected for
        // this process.
        const resolvedG = processResolver.resolveGoroutineID(gid);
        const recordingID = recordingContext.recordingID;
        clickURL = `${getAppXUrl(appConfig)}/recordings/${recordingID}/goroutines/${resolvedG.ID}.${resolvedG.duckDBProcessUUID}/flamegraph`;
      }

      return (
        <GoroutineLink
          processID={gid.ProcessID}
          goroutineID={gid.ID}
          clickURL={clickURL}
          processClickURL={props.snapshotState?.setProcessURL(gid.ProcessID)}
        />
      );
    }
    case ColumnType.TableName:
      return <TableNameLink tableName={val} />;
    default:
      return <>{val}</>;
  }
}

// TableNameLink renders a link rendered as <TableName>. Clicking on the
// table name alters the filters to focus on the table.
function TableNameLink({tableName}: {tableName: string}) {
  const spec = useContext(SpecContext);
  const snapshotState = useSnapshotState();

  const tableRef = tableNameToTableReference(spec, tableName);
  if (!tableRef) {
    return <>{tableName}</>;
  }

  return (
    <Button
      sx={{minWidth: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0}}
      size={"small"}
      variant={"text"}
      component={ReactLink}
      to={snapshotState.tablesController.showTableURL(tableRef)}
    >
      {tableName}
    </Button>
  );
}

// TableCell renders one cell in the table. It wraps CellContent with UI and
// logic for alternatively showing the cell in a modal dialog.
export default function TableCell(props: CellProps): React.JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const [linksPopperVisible, setLinksPopperVisible] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    // A div as big as the containing element. We'll compare the dimensions of
    // this div with the dimensions of the content.
    <div
      style={{
        width: "100%",
        height: "100%",
        // Setting a position causes this div to be a "positioned ancestor",
        // which makes the div below using "position: absolute" be relative to
        // this div.
        position: "relative",
      }}
    >
      {/*A div that expands to fit the content (even when the content is larger
      that the space available). We'll compare the dimensions of this div with
      the dimensions of the parent.*/}
      <div style={{width: "fit-content"}}>
        <CellContent {...props} onExpand={props.onExpand} />
      </div>

      {
        <Stack
          direction="row"
          spacing={1}
          sx={{position: "absolute", top: "0px", right: "0px"}}
        >
          {props.links.length > 0 && (
            <>
              <IconButton
                ref={anchorRef}
                size="small"
                sx={{m: 0, p: 0, minWidth: 0}}
                onClick={() => {
                  setLinksPopperVisible(true);
                }}
              >
                <LinkIcon />
              </IconButton>
              {linksPopperVisible && (
                <ClickAwayListener
                  onClickAway={() => setLinksPopperVisible(false)}
                >
                  <Popper
                    anchorEl={anchorRef.current}
                    open={true}
                    sx={{maxWidth: "50rem"}}
                  >
                    <CellLinks
                      links={props.links}
                      controller={props.controller}
                    />
                  </Popper>
                </ClickAwayListener>
              )}
            </>
          )}
          {
            <IconButton
              sx={{
                padding: 0,
                // Change the ripple background color to a darker gray, for more
                // contrast.
                "&:hover": {
                  backgroundColor: "rgb(216, 216, 216)",
                },
              }}
              onClick={showModal}
              size="small"
            >
              <CropFreeIcon sx={{width: "20px"}} />
            </IconButton>
          }
        </Stack>
      }
      {modalVisible && (
        <ModalCell
          {...props}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
    </div>
  );
}

function ModalCell(
  props: CellContentProps & {
    onClose: () => void;
  },
): React.JSX.Element {
  return (
    <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth="xl">
      <DialogTitle>{props.name}</DialogTitle>
      <DialogContent>
        <CellContent
          value={props.value}
          type={props.type}
          name={props.name}
          links={props.links}
          processInfo={props.processInfo}
          snapshotState={props.snapshotState}
        />
      </DialogContent>
    </Dialog>
  );
}

// Render a value that is either a JSON value represented as a string, or a
// non-JSON string. If the value is not valid JSON, we render it as a string. If
// the value is a JSON object (i.e. not a JSON primitive), we render it in a
// JsonView. If the value is a JSON primitive, we render it directly. This means
// that a non-JSON string and a JSON string render the same:
// renderPossiblyJSONValue(`foo`) and renderPossiblyJSONValue(`"foo"`) represent
// the same thing.
//
// onExpand, if set, is called when the user expands or collapses a JSON field.
//
// defaultExpandedLevels, if set, controls how many level of the JSON are
// expanded by default. Set to 0 to collapse everything. If not set, a heuristic
// is used.
//
//
// NOTE: For JSON rendering, I've tried a couple of components. Other
// than JsonView, the others made resizing the columns of the table
// extremely slow. I've tried:
// https://github.com/uiwjs/react-json-view
// https://github.com/TexteaInc/json-viewer
// I haven't tried setting `columnResizeMode: 'onEnd'` on the table;
// that might also fix the problem at the cost of a different resizing
// experience.
export function renderPossiblyJSONValue(
  val: string,
  onExpand?: (expanded: boolean) => void,
  defaultExpandedLevels?: number,
): React.JSX.Element {
  // Attempt to parse as JSON. If that fails, we'll simply render the string.
  let parsed: any;
  try {
    // We use lossless-json to parse the JSON. This is because JSON.parse loses
    // the precision of large integers.
    parsed = parseLoseless(val, null /* reviver */, parseNumber);
  } catch (_e) {
    parsed = val;
  }

  if (parsed === null) return <span className="text-muted">NULL</span>;

  switch (typeof parsed) {
    default:
      return <>{val}</>;
    case "string":
      if (parsed == "") return <span className="text-muted">""</span>;
      return <>{parsed}</>;
    case "bigint":
      // Convert the bigint to a string, otherwise simply {parsed} does not
      // render anything.
      return <>{parsed.toString()}</>;
    case "boolean":
      return (
        <Typography color={"#2aa198"} fontSize={".9rem"}>
          {parsed.toString()}
        </Typography>
      );
    case "object":
      // As a hack, if the object has only one key, we expand it by an extra
      // level. This works out in some cases where the object is an interface
      // and the first level is just the name of the type.
      //
      // In the future when we have more semantic information about the contents
      // of the data, we can consider something fancier.
      const collapsed =
        defaultExpandedLevels == 0
          ? true
          : defaultExpandedLevels
            ? defaultExpandedLevels
            : Object.keys(parsed).length == 1
              ? 3
              : 2;
      return (
        <JsonView
          value={parsed}
          collapsed={collapsed}
          displayDataTypes={false}
          displayObjectSize={false}
          onExpand={({expand}) => {
            // expand seems to be false when the field is being expanded, and
            // true when it's being collapsed.
            onExpand?.(!expand);
          }}
          style={JSONViewTheme()}
        >
          {/* Customize the rendering of big numbers so that they're not
          rendered with a trailing 'n' like "123n". */}
          <JsonView.Bigint
            render={({children, ...reset}, {type, value, keyName}) => {
              return <>{(value as bigint).toString()}</>;
            }}
          />
        </JsonView>
      );
  }
}

// CellLinks renders a cell's links to other tables.
function CellLinks({
  links,
  controller,
  snapshotState,
}: {
  links: resolvedLinksInfo[];
  controller: tablesURLController;
  snapshotState?: SnapshotState;
}): React.JSX.Element {
  const urls: string[] = links.map((l) => {
    const query: string = buildQueryForLink(
      l.TargetTableName,
      l.TargetCols,
      l.ColValues,
    );
    return controller.tableWithQueryURL(l.TargetTableRef, query);
  });

  return (
    <Paper>
      <List>
        {links.map((l, idx) => (
          <ListItem key={idx}>
            <ListItemText>
              {l.NumLinks == 1 && l.SampleGoroutineID ? (
                <>
                  Goroutine{" "}
                  <GoroutineLink
                    key={idx}
                    processID={l.SampleGoroutineID.ProcessID}
                    goroutineID={l.SampleGoroutineID.ID}
                    clickURL={snapshotState?.goroutineURL(
                      l.SampleGoroutineID.ProcessID,
                      l.SampleGoroutineID.ID,
                    )}
                    processClickURL={snapshotState?.setProcessURL(
                      l.SampleGoroutineID.ProcessID,
                    )}
                  />
                </>
              ) : (
                <>{l.NumLinks}</>
              )}{" "}
              matches in{" "}
              <Link component={ReactLink} to={urls[idx]}>
                {l.TargetTableDisplayName}
              </Link>
              {": "}
              {l.LinkNote}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

// buildQueryForLink builds a query that selects all rows from a target table
// that are linked to a given row. The given row is identified by the values of
// the link columns.
function buildQueryForLink(
  targetTableName: string,
  targetCols: string[],
  colValues: (string | null)[],
): string {
  if (targetCols.length != colValues.length) {
    throw new Error(
      `targetCols.length (${targetCols.length}) != colValues.length (${colValues.length})`,
    );
  }
  const clauses: string[] = targetCols.reduce((acc, colName, i): string[] => {
    let clause = `"${colName}"`;
    // Construct the value as a SQLite string, with single quotes escaped as
    // two single quotes.
    const val = colValues[i]?.replace("'", "''");
    if (val == null) {
      clause += " IS NULL";
      acc.push(clause);
    } else {
      clause += ` = '${val}'`;
      acc.push(clause);
    }
    return acc;
  }, [] as string[]);

  return `SELECT * FROM ${targetTableName} WHERE ${clauses.join(" AND ")}`;
}
