import {
  FilteringOptionType,
  FunctionName,
  StacksFilter,
} from "src/__generated__/graphql";
import {ClickedFrame, ProgramCounter} from "../util/types";
import {SetURLSearchParams, useParams, useSearchParams} from "react-router-dom";
import {createContext, PropsWithChildren, useContext} from "react";
import {
  computeSearchParams,
  EnumParamUpdater,
  JSONParamUpdater,
  ParamUpdater,
  searchParamsURL,
  stateFromURL,
  UpdateSpec,
  URLState,
} from "src/util/url.ts";
import {
  makeTablesParamUpdaters,
  tablesURLController,
} from "src/components/tables/util.tsx";

// frameReference is a reference to a frame in a stack trace. It is used to
// open the drawer for the frame spec.
export interface frameReference {
  // The fully-qualified name of the function to render.
  functionName: string;
  inlined: boolean;
  // pc is the address (program counter) corresponding to this frame. It is used
  // to suggest which variables are available at the respective location.
  pc: ProgramCounter;
  binaryID: string;
}

// The SnapshotTab enum represents the different tabs that are available in the
// top-level of the snapshot page.
export enum SnapshotTab {
  Goroutines = "goroutines",
  Tables = "tables",
}

// The GoroutinesTabMode enum represents the different modes that are available
// within the Goroutines tab.
export enum GoroutinesTabMode {
  Flamegraph = "flamegraph",
  Stacks = "stacks",
}

// paramUpdaters is the mapping of URL parameters to an object that have get and
// update methods to read and write the URL parameters as well as a param string
// property with the name of the parameter.
const paramUpdaters = {
  filters: new ParamUpdater<StacksFilter[], StacksFilter[]>(
    "filter",
    (param, params) => params.getAll(param).map((f) => JSON.parse(f)),
    (param, params, filters: StacksFilter[]) => {
      const encodedFilters = filters.map((filter) => JSON.stringify(filter));
      params.delete(param);
      for (const f of encodedFilters) {
        params.append(param, f);
      }
    },
  ),
  selectedFrame: new JSONParamUpdater<frameReference>("selectedFrame"),
  snapshotTab: new EnumParamUpdater("tab", SnapshotTab.Goroutines, SnapshotTab),
  goroutinesMode: new EnumParamUpdater(
    "goroutinesMode",
    GoroutinesTabMode.Flamegraph,
    GoroutinesTabMode,
  ),
  hiddenNodes: new JSONParamUpdater<number[]>("hiddenNodes"),
  focusedNodes: new JSONParamUpdater<number[]>("focusedNodes"),
};

// The type of the state field of the SnapshotState object.
type State = URLState<typeof paramUpdaters> & {snapshotID: number};

export class SnapshotState {
  // The URLState wrapped by this SnapshotState.
  readonly state: State;

  readonly searchParams: URLSearchParams;
  private readonly setSearchParams: SetURLSearchParams;
  readonly tablesController: tablesURLController;

  constructor(
    snapshotID: number,
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
  ) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
    const stateProps = stateFromURL(searchParams, paramUpdaters);
    this.state = {...stateProps, snapshotID};
    this.tablesController = new tablesURLController(
      searchParams,
      this.setSearchParams,
      // extraUpdater - make all URLs produced by the tablesController switch to
      // the Tables tab (if that tab is not already the active one).
      makeTablesParamUpdaters(true /* showData */),
      (url: URLSearchParams): URLSearchParams => {
        return paramUpdaters.snapshotTab.update(url, SnapshotTab.Tables);
      },
    );
  }

  // computeSearchParams takes an object with the properties that need to be
  // updated, and returns a URLSearchParams object that represents the updated
  // state. Properties in the map which exist but with the value "undefined"
  // are removed from the parameters. Properties which are not present are left
  // alone.
  computeSearchParams = (
    args: UpdateSpec<typeof paramUpdaters>,
  ): URLSearchParams => {
    return computeSearchParams(this.searchParams, args, paramUpdaters);
  };

  updateSearchParam = (args: UpdateSpec<typeof paramUpdaters>): void => {
    this.setSearchParams(this.computeSearchParams(args));
  };

  // Set the filters to the given list of filters.
  setFilters = (filters: StacksFilter[]) => {
    this.updateSearchParam({filters});
  };

  // Removes the old filters of type overwriteTypes, and adds the new ones.
  // If overwriteTypes is unspecified, it's the union of all filter types given.
  // Also takes in a set of other search params to update in this change of
  // filters.
  overwriteFilters(
    filters: StacksFilter[],
    overwriteTypes?: FilteringOptionType[],
    extraUpdates: UpdateSpec<typeof paramUpdaters> = {},
  ) {
    if (!overwriteTypes) {
      overwriteTypes = filters.map((filter) => filter.Type);
    }
    const removedTypes = new Set(overwriteTypes);
    const newFilters = [
      // Preserved existing filters.
      ...this.state.filters.filter((f) => !removedTypes.has(f.Type)),
      ...filters,
    ];
    this.updateSearchParam({
      filters: newFilters,
      ...extraUpdates,
    });
  }

  private searchParamsURL(params: URLSearchParams): string {
    return `?${params.toString()}`;
  }

  setTabToGoroutinesURL = (): string => {
    return this.searchParamsURL(
      this.computeSearchParams({snapshotTab: SnapshotTab.Goroutines}),
    );
  };

  setTabToTablesURL = (clearSelectedTable: boolean): string => {
    let sp = this.searchParams;
    if (clearSelectedTable) {
      sp = this.tablesController.listTablesParams();
    }
    return searchParamsURL(
      sp,
      {snapshotTab: SnapshotTab.Tables},
      paramUpdaters,
    );
  };

  // setSelectedFrame sets the selected frame in the URL.
  setSelectedFrame = (selectedFrame: ClickedFrame): void => {
    this.updateSearchParam({
      selectedFrame: {
        functionName: selectedFrame.FuncQualifiedName,
        inlined: selectedFrame.Inlined,
        pc: selectedFrame.PC,
        binaryID: selectedFrame.BinaryID,
      },
    });
  };

  clearSelectedFrame = (): void => {
    this.updateSearchParam({selectedFrame: undefined});
  };

  // setProcessURL returns the URL to the page that represents a process having been
  // selected from the current page.
  setProcessURL = (processID: number): string => {
    return `?${this.setProcessSearchParams(processID).toString()}`;
  };

  setGoroutinesModeURL = (goroutinesMode: GoroutinesTabMode): string => {
    const searchParams = this.computeSearchParams({goroutinesMode});
    return `?${searchParams.toString()}`;
  };

  private setProcessSearchParams = (processID: number): URLSearchParams => {
    // Remove existing filters that are unlikely to make sense with a new
    // process.
    const newFilters = this.state.filters.filter(
      (f) =>
        f.Type != FilteringOptionType.ProcessSnapshot &&
        f.Type != FilteringOptionType.StackPrefix &&
        f.Type != FilteringOptionType.Goroutine,
    );
    // Add a new process snapshot filter.
    newFilters.push({
      Type: FilteringOptionType.ProcessSnapshot,
      ProcessID: processID,
    });
    return this.computeSearchParams({filters: newFilters});
  };

  // goroutineURL returns the URL of the stacks view filtered to a specific
  // goroutine.
  goroutineURL = (processID: number, goroutineID: number): string => {
    // A goroutine filter is the most specific one, so there's no point in
    // keeping any other filters.
    const goroutineFilters: StacksFilter[] = [
      {
        Type: FilteringOptionType.Goroutine,
        ProcessID: processID,
        GoroutineID: goroutineID,
      },
    ];
    const newFilters: StacksFilter[] = goroutineFilters;

    const searchParams = this.computeSearchParams({
      filters: newFilters,
      goroutinesMode: GoroutinesTabMode.Stacks,
      snapshotTab: SnapshotTab.Goroutines,
    });
    return `?${searchParams.toString()}`;
  };

  functionFilterURL = (functionName: FunctionName): string => {
    // Remove the existing filter on function name, if it exists.
    const newFilters = this.state.filters.filter(
      (f) => f.Type != FilteringOptionType.Function,
    );
    // Add a new function filter.
    newFilters.push({
      Type: FilteringOptionType.Function,
      Package: functionName.Package,
      TypeName: functionName.Type,
      FuncName: functionName.QualifiedName,
    });
    const searchParams = this.computeSearchParams({filters: newFilters});
    return `?${searchParams.toString()}`;
  };
}

const PageHeaderContext = createContext<SnapshotState>(undefined as never);

export const SnapshotStateProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pathParams = useParams();
  const snapshotID = parseInt(pathParams.snapshotID!);
  const snapshotState = new SnapshotState(
    snapshotID,
    searchParams,
    setSearchParams,
  );
  return (
    <PageHeaderContext.Provider value={snapshotState}>
      {children}
    </PageHeaderContext.Provider>
  );
};

export function useSnapshotState(): SnapshotState {
  return useContext(PageHeaderContext);
}
