import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle.tsx";
import EditableLabel from "src/util/editable-label.tsx";
import React, {Suspense, useState} from "react";
import AvailableVars from "src/components/AvailableVars";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CollectExprSpec,
  ColumnSpec,
  ColumnType,
} from "src/__generated__/graphql.ts";
import {ProgramCounter} from "src/util/types.ts";
import TextField from "@mui/material/TextField";
import {columnTypes} from "src/components/tables/util.tsx";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {TableNoDataMessage} from "@components/TableNoDataMessage.tsx";
import {IconEdit} from "@components/icons/IconEdit.tsx";
import CloseIcon from "@mui/icons-material/Close";
import {
  FunctionSpecEditor,
  FunctionSpecEditorInterface,
  Spec,
  specType,
} from "@util/function-spec-editing.tsx";

type FunctionTableEditorProps<T extends specType> = {
  funcQualifiedName: string;
  // The function snapshot or events spec to edit. If undefined, the editor is
  // empty.
  tableSpec: Spec<T> | undefined;
  specEditor: FunctionSpecEditorInterface;

  labels: {
    variablesLabel: string;
    variablesTooltip: string;
    capturedExprTooltip: string;
    tableNameTooltip: string;
    extraColsTooltip: string;
  };

  // binaryID is either the ID of the binary to be used when listing available
  // variables, or a function to call to get the binary ID. If it is a function,
  // the expectation is that calling the function will eventually cause a
  // re-render of this component with the binary ID set to a string.
  binaryID: string | (() => void);

  // If binaryID is set, a program counter can also be set. It will be used to
  // display variables that are available at this particular code location.
  pc?: ProgramCounter;
  paramsOnly: boolean;
};

// FunctionTableEditor renders the editor for a frame or events table. It allows
// selecting variables to capture and controlling the table's columns.
export function FunctionTableEditor<T extends specType>(
  props: FunctionTableEditorProps<T>,
): React.JSX.Element {
  const [editingVars, setEditingVars] = useState(false);
  const [addingColumn, setAddingColumn] = useState(false);

  const collectExprs = props.tableSpec?.collectExprs || [];
  const extraCols = props.tableSpec?.extraColumns || [];
  const tableName = props.tableSpec?.tableName ?? "";

  // columnName computes the name of a column. If the column explicitly has a
  // name, that name is returned. Otherwise, the expression is used as the name.
  function columnName(expr: CollectExprSpec): string {
    const col = expr.column;
    if (col && col.name && col.name != "") {
      return col.name;
    }
    return expr.expr;
  }

  return (
    <Stack
      gap={1}
      onClick={(e) => {
        // Sometimes the FunctionTableEditor is rendered inside a TreeItem. If
        // we let the click propagate, we get a weird visual effect because the
        // respective TreeItem gets re-focused, and also., if we had just
        // clicked on a text box, we lose the focus on that text box.
        e.stopPropagation();
      }}
    >
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography variant="body4" color="secondary">
          {props.labels.variablesLabel}
        </Typography>
        <HelpCircle tip={props.labels.variablesTooltip} />
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: 300}}>Expression</TableCell>
              <TableCell sx={{minWidth: 300}}>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <span>Table column name</span>
                  <HelpCircle tip={props.labels.capturedExprTooltip} small />
                </Stack>
              </TableCell>
              <TableCell sx={{width: 40}} align="right">
                Visibility
              </TableCell>
              <TableCell sx={{width: 40}} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!collectExprs.length && <TableNoDataMessage />}

            {collectExprs.map((expr: CollectExprSpec) => (
              <TableRow key={expr.expr}>
                <TableCell>{expr.expr}</TableCell>
                <TableCell>
                  <EditableLabel
                    text={columnName(expr)}
                    allowEmpty={true}
                    onSave={async (newName: string) =>
                      await props.specEditor.onColumnNameUpdated(
                        expr.expr,
                        newName,
                      )
                    }
                    editTooltip={"Edit the column name"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Stack display="grid" gridTemplateColumns="40px">
                    <Tooltip
                      title={
                        "Change column visibility to " +
                        (expr.column.hidden ? "visible" : "hidden")
                      }
                    >
                      <Checkbox
                        icon={<VisibilityIcon color="primary" />}
                        checkedIcon={<VisibilityOffIcon color="info" />}
                        checked={expr.column.hidden}
                        onChange={(e) =>
                          void props.specEditor.onColumnHiddenUpdated(
                            expr.expr,
                            e.target.checked,
                          )
                        }
                      />
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Tooltip title={"Delete column"}>
                    <IconButton
                      onClick={() =>
                        void props.specEditor.onExpressionCollectChange(
                          expr.expr,
                          false /* checked */,
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/*If we're in "editing the variables" mode and we know a binary ID, render*/}
      {/*the variables editor.*/}
      {editingVars && typeof props.binaryID == "string" ? (
        <>
          <Box>
            <Button
              variant="contained"
              onClick={() => setEditingVars(false)}
              sx={{my: 2}}
            >
              Hide variables
            </Button>
          </Box>
          <Card>
            <CardHeader
              title={
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h2">
                    Function variables
                    {props.pc != undefined &&
                      " available at PC 0x" + props.pc.toString(16)}
                  </Typography>
                  <HelpCircle
                    tip={
                      props.pc
                        ? `The non-grayed out variables are the ones available to be ` +
                          `captured at the specific code location of the provided stack ` +
                          `frame. Other variables can also be selected, but they will ` +
                          `not actually be captured for stack frames at the current ` +
                          `code location.`
                        : `All variables in the function will be rendered as ` +
                          `available to capture since we are not in the context of a ` +
                          `particular program counter (i.e. code location). However, at ` +
                          `runtime some of the variables might not be available, in ` +
                          `which case they will not be captured in the snapshot.`
                    }
                  />
                </Stack>
              }
            />

            <Suspense
              fallback={
                <Box textAlign="center">
                  <CircularProgress />
                </Box>
              }
            >
              <Box
                display="grid"
                gridTemplateColumns="minmax(200px, 1fr)"
                sx={{overflowX: "auto"}}
              >
                <AvailableVars
                  funcQualifiedName={props.funcQualifiedName}
                  collectExprs={collectExprs}
                  binaryID={props.binaryID}
                  pc={props.pc}
                  onExpressionCollectChanged={
                    props.specEditor.onExpressionCollectChange
                  }
                  onStaleExpressionDeleted={
                    props.specEditor.onExpressionDeleted
                  }
                  paramsOnly={props.paramsOnly}
                />
              </Box>
            </Suspense>
          </Card>
        </>
      ) : (
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              setEditingVars(true);
              if (typeof props.binaryID === "function") {
                props.binaryID();
              }
            }}
            sx={{mt: 2}}
          >
            Add or remove variables
          </Button>
        </Box>
      )}

      <Stack flexDirection="row" alignItems="center" gap={1} mt={3}>
        <Typography variant="body4" color="secondary">
          Additional table columns
        </Typography>
        <HelpCircle tip={props.labels.extraColsTooltip} />
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{minWidth: 200}}>Column name</TableCell>
              <TableCell sx={{minWidth: 200}}>Expression</TableCell>
              <TableCell sx={{minWidth: 200}}>Data type</TableCell>
              <TableCell sx={{width: 150}} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {extraCols.map((column: ColumnSpec) => (
              <ColumnEditorRow
                key={column.name}
                column={column}
                onColumnDeleted={props.specEditor.onExtraColumnDeleted}
                onColumnEdited={props.specEditor.onExtraColumnEdited}
              />
            ))}
          </TableBody>

          <TableFooter>
            {!addingColumn ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Button onClick={() => setAddingColumn(true)}>
                    Add extra column
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              <ColumnEditorRow
                isNewRow={true}
                onColumnAdded={async (...params) => {
                  await props.specEditor.onExtraColumnAdded(...params);
                  setAddingColumn(false);
                  return true;
                }}
                onAddingCancel={() => setAddingColumn(false)}
              />
            )}
          </TableFooter>
        </Table>
      </TableContainer>

      <Box sx={{mt: 3}}>
        <EditableLabel
          label={"SQL table name"}
          labelTooltip={props.labels.tableNameTooltip}
          text={tableName}
          allowEmpty={false}
          editTooltip={"Edit table name"}
          onSave={props.specEditor.onTableNameUpdated}
          showAsTextField
        />
      </Box>
    </Stack>
  );
}

type ColumnEditorRowProps<T extends specType> = {
  // column is required unless isNewRow is set, in which case column should not
  // be specified.
  column?: ColumnSpec;
  isNewRow?: boolean;
  onColumnDeleted?: FunctionSpecEditor<T>["onExtraColumnDeleted"];
  onColumnEdited?: FunctionSpecEditor<T>["onExtraColumnEdited"];
  onColumnAdded?: FunctionSpecEditor<T>["onExtraColumnAdded"];
  onAddingCancel?: () => void;
};

// ColumnEditorRow renders a row in the table of extra columns. It allows both
// viewing and editing the extra column.
function ColumnEditorRow<T extends specType>(
  props: ColumnEditorRowProps<T>,
): React.JSX.Element {
  const {
    column,
    isNewRow,
    onColumnDeleted,
    onColumnEdited,
    onColumnAdded,
    onAddingCancel,
  } = props;

  const [name, setName] = useState(column?.name ?? "");
  const [expr, setExpr] = useState(column?.expr ?? "");
  const [type, setType] = useState<ColumnType>(
    column?.type ?? ColumnType.String,
  );

  const [editing, setEditing] = useState(isNewRow ?? false);

  const onSave = async () => {
    if (isNewRow) {
      await onColumnAdded?.(name, expr, type, false);
      return;
    }

    await onColumnEdited?.(column!.name, name, expr, type, column!.hidden);
  };

  if (editing) {
    const validToSave = name !== "" && expr !== "";

    return (
      <TableRow>
        <TableCell>
          <TextField
            placeholder="Column name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell>
          <TextField
            placeholder="Column expression"
            value={expr}
            onChange={(event) => setExpr(event.target.value)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell>
          <Select
            label={"Column type"}
            value={type}
            onChange={(event) => {
              setType(event.target.value as ColumnType);
            }}
            displayEmpty
            size="small"
            fullWidth
          >
            <MenuItem value={""} disabled sx={{display: "none"}}>
              <Typography variant="body3" color="secondary">
                Column type
              </Typography>
            </MenuItem>
            {columnTypes.map(([label, type]) => (
              <MenuItem key={type} value={type}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell>
          <Stack display="grid" gridTemplateColumns="40px 40px">
            <Tooltip title={isNewRow ? "Add the new column" : "Save changes"}>
              <span>
                <IconButton
                  disabled={!validToSave}
                  onClick={() => {
                    void (async () => {
                      await onSave();
                      setEditing(false);
                    })();
                  }}
                >
                  <SaveIcon color={validToSave ? "primary" : "info"} />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton
              onClick={() => {
                setEditing(false);
                setName(column?.name ?? "");
                setExpr(column?.expr ?? "");
                setType(column?.type ?? ColumnType.String);
                onAddingCancel?.();
              }}
            >
              <Tooltip title={"Cancel"}>
                <CloseIcon color="primary" />
              </Tooltip>
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{expr}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>
        <Stack display="grid" gridTemplateColumns="40px 40px 40px">
          <Tooltip title={"Edit column"}>
            <IconButton onClick={() => setEditing(true)}>
              <IconEdit />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Delete column"}>
            <IconButton onClick={() => void onColumnDeleted!(column!.name)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={
              "Change column visibility to " +
              (column!.hidden ? "visible" : "hidden")
            }
          >
            <Checkbox
              icon={<VisibilityIcon color="primary" />}
              checkedIcon={<VisibilityOffIcon color="info" />}
              checked={column!.hidden}
              onChange={(e) => {
                const hidden = e.target.checked;
                void onColumnEdited?.(column!.name, name, expr, type, hidden);
              }}
            />
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
