import {useApolloClient} from "@apollo/client";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {
  ProcessPprofAddresses,
  ProcessSelector,
} from "@components/ProcessSelector";
import {
  ProcessSelectionJSONSchema,
  processSelectionToGraph,
  useProcessSelection,
} from "@components/ProcessSelector/helpers/processSelection.ts";
import {suggestPackagePrefixes} from "@components/filter";
import type {
  FullSnapshotSpecFragment,
  FunctionLines,
  FunctionName,
  FunctionSpec,
  FunctionStartEventSpec,
  LineSpec,
} from "@graphql/graphql.ts";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CodeIcon from "@mui/icons-material/Code";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import {debouncedListFileLinesFromBinary} from "@util/debouncedListFileLinesFromBinary";
import {debouncedListFunctionsFromBinary} from "@util/debouncedListFunctionsFromBinary";
import {addOrUpdateFunctionSpec} from "@util/queries";
import {exhaustiveCheck, funcOrMethodNameWithShortPkg} from "@util/util";
import type React from "react";
import {
  type Key,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import {gql} from "src/__generated__";
import {FunctionTableEditor} from "src/components/FunctionTableEditor.tsx";
import {
  functionAutocompletionOption,
  moduleAutocompletionOption,
  packageAutocompletionOption,
  packagePrefixAutocompleteOption,
  toastError,
  typeAutocompletionOption,
} from "src/components/tables/util.tsx";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {useBinarySelectionDialog} from "src/providers/binary-selection-dialog.tsx";
import {useConfirmationDialog} from "src/providers/confirmation-dialog.tsx";
import {SpecContext} from "src/providers/spec-provider.tsx";
import {FunctionSpecEditor} from "src/util/function-spec-editing.tsx";
import {
  BooleanParamUpdater,
  computeSearchParams,
  NumberParamUpdater,
  ParamUpdater,
  stateFromURL,
  StringsParamUpdater,
  type UpdateSpec,
  ZodParamUpdater,
} from "src/util/url";
import {match} from "ts-pattern";
import {z, type ZodType} from "zod";
import {SelectorBinary} from "../../components/SelectorBinary";
import LineProbeItem from "./components/LineProbeItem";
import FileAutocomplete from "./components/FileAutocomplete";

type autocompleteOptionExistingEvent =
  | functionAutocompletionOption
  | packageAutocompletionOption
  | typeAutocompletionOption
  | moduleAutocompletionOption
  | packagePrefixAutocompleteOption;

class autocompleteOptionNewFunction {
  type = "newFunction" as const;
  funcName: FunctionName;

  constructor(funcName: FunctionName) {
    this.funcName = funcName;
  }

  render = (
    props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
  ): ReactNode => {
    // The "key" property needs to be passed explicitly. See
    // https://github.com/mui/material-ui/issues/39833.
    const {key, ...optionProps} = props;
    return (
      <ListItem key={key} {...optionProps}>
        {this.funcName.QualifiedName}
      </ListItem>
    );
  };
}

class RecordingType {
  readonly snapshots: boolean;
  readonly eventLogs: boolean;
  readonly executionTraces: boolean;

  constructor(
    snapshots: boolean,
    eventLogs: boolean,
    executionTraces: boolean,
  ) {
    this.snapshots = snapshots;
    this.eventLogs = eventLogs;
    this.executionTraces = executionTraces;
  }

  something(): boolean {
    return this.snapshots || this.eventLogs || this.executionTraces;
  }

  durationRecording(): boolean {
    return this.executionTraces || this.eventLogs;
  }

  recordingButtonLabel(): string {
    return match({
      snapshots: this.snapshots,
      eventLogs: this.eventLogs,
      executionTraces: this.executionTraces,
    })
      .with(
        {snapshots: true, eventLogs: true, executionTraces: true},
        () => "Capture snapshot, events, and execution trace recording",
      )
      .with(
        {snapshots: true, eventLogs: true, executionTraces: false},
        () => "Capture snapshot and events recording",
      )
      .with(
        {snapshots: true, eventLogs: false, executionTraces: true},
        () => "Capture snapshot and execution trace recording",
      )
      .with(
        {snapshots: false, eventLogs: true, executionTraces: true},
        () => "Capture events and execution trace recording",
      )
      .with(
        {snapshots: true, eventLogs: false, executionTraces: false},
        () => "Capture snapshot recording",
      )
      .with(
        {snapshots: false, eventLogs: true, executionTraces: false},
        () => "Capture events recording",
      )
      .with(
        {snapshots: false, eventLogs: false, executionTraces: true},
        () => "Capture execution trace recording",
      )
      .with(
        {snapshots: false, eventLogs: false, executionTraces: false},
        () => "Capture recording",
      )
      .exhaustive();
  }
}

// autocompleteOptionSelectBinary represents an option that renders as a button
// allowing the user to trigger the binary selection dialog. We tell the
// Autocomplete that this option is disabled, so that it's not possible to
// select it; you can only interact with it by pressing the button we render
// inside it.
class autocompleteOptionSelectBinary {
  type = "selectBinary" as const;
  readonly promptForBinarySelection: () => Promise<void>;

  constructor(promptForBinarySelection: () => Promise<void>) {
    this.promptForBinarySelection = promptForBinarySelection;
  }

  render = (
    props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
  ): ReactNode => {
    // The "key" property needs to be passed explicitly. See
    // https://github.com/mui/material-ui/issues/39833.
    const {key, ..._optionProps} = props;
    return (
      // NOTE: We don't pass {...optionProps} to the ListItem, because we don't
      // want the CSS classes for a disabled option to apply.
      <ListItem key={key}>
        <Box onClick={(e) => e.stopPropagation()}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              void this.promptForBinarySelection();
            }}
          >
            Select binary
          </Button>
          to see new function suggestions
        </Box>
      </ListItem>
    );
  };
}

type autocompleteOption =
  | autocompleteOptionExistingEventWrapper
  | autocompleteOptionNewFunction
  | autocompleteOptionSelectBinary;

function isExistingEvent(
  opt: autocompleteOption,
): opt is autocompleteOptionExistingEventWrapper {
  return (
    opt.type == "function" ||
    opt.type == "package" ||
    opt.type == "packagePrefix" ||
    opt.type == "type" ||
    opt.type == "module"
  );
}

class autocompleteOptionExistingEventWrapper {
  opt: autocompleteOptionExistingEvent;
  // Keep track of the module that this option belongs to. This is needed to
  // match the options against a selected module.
  moduleName: string;

  constructor(opt: autocompleteOptionExistingEvent, moduleName: string) {
    this.opt = opt;
    this.moduleName = moduleName;
  }

  get type(): string {
    return this.opt.type;
  }
}

const CAPTURE_RECORDING = gql(/* GraphQL */ `
  mutation captureRecording($input: CaptureRecordingInput!) {
    captureRecording(input: $input) {
      recordingID
      eventLogID
      snapshotID
    }
  }
`);

function specToAutocompleteSuggestions(
  tree: EventsTree,
): autocompleteOptionExistingEventWrapper[] {
  const suggestions: autocompleteOptionExistingEventWrapper[] = [];
  for (const module of tree.modules) {
    suggestions.push(
      new autocompleteOptionExistingEventWrapper(
        new moduleAutocompletionOption(module.moduleName),
        module.moduleName,
      ),
    );
    for (const pkg of module.packages) {
      suggestions.push(
        new autocompleteOptionExistingEventWrapper(
          new packageAutocompletionOption(pkg.packageName),
          module.moduleName,
        ),
      );
      for (const type of pkg.types) {
        suggestions.push(
          new autocompleteOptionExistingEventWrapper(
            new typeAutocompletionOption(pkg.packageName, type.typeName),
            module.moduleName,
          ),
        );
        for (const func of type.events) {
          suggestions.push(
            new autocompleteOptionExistingEventWrapper(
              new functionAutocompletionOption(func.func),
              module.moduleName,
            ),
          );
        }
      }
      for (const func of pkg.functionEvents) {
        suggestions.push(
          new autocompleteOptionExistingEventWrapper(
            new functionAutocompletionOption(func.func),
            module.moduleName,
          ),
        );
      }
    }
  }
  return suggestions;
}

// matchAutocompleteOptions takes a list of options and a query string and
// returns the options that match the query. The match field of the returned
// options is set.
function matchAutocompleteOptions(
  options: autocompleteOption[],
  query: string,
): autocompleteOption[] {
  const matches: autocompleteOption[] = [];
  const caseSensitive = query.toLowerCase() != query;

  // Keep track of the package prefix suggestions generated, as multiple
  // packages can generate the same prefix.
  const packagePrefixes = new Set<string>();
  // Usually we don't start with any package prefix suggestions in the input.
  // However, if a package prefix is selected, that one is also part of
  // `options`. So, we initialize packagePrefixes with any such option, so we
  // don't generate it again.
  for (const o of options) {
    if (isExistingEvent(o) && o.opt.type == "packagePrefix") {
      packagePrefixes.add(o.opt.pkg);
    }
  }

  for (const o of options) {
    // The functions from binary have already been filtered to the ones matching
    // the query.
    if (!isExistingEvent(o)) {
      matches.push(o);
      continue;
    }

    if (o.opt.match(query, caseSensitive)) {
      matches.push(o);
    }

    if (o.opt.type == "package") {
      // Generate package prefix suggestions.
      const prefixSuggestion = suggestPackagePrefixes(o.opt.pkgName, query);
      for (const [pkgPrefix, _highlighted] of prefixSuggestion) {
        if (packagePrefixes.has(pkgPrefix)) {
          continue;
        }
        const p = new packagePrefixAutocompleteOption(pkgPrefix);
        if (p.match(query, caseSensitive)) {
          matches.push(
            new autocompleteOptionExistingEventWrapper(p, o.moduleName),
          );
          packagePrefixes.add(pkgPrefix);
        } else {
          throw new Error(
            `suggestion was expected to match: ${pkgPrefix} query ${query}`,
          );
        }
      }
    }
  }

  return matches;
}

function getOptionKey(option: autocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  if (isExistingEvent(option)) {
    return option.opt.key();
  }
  if (option.type == "selectBinary") {
    return "select binary";
  }
  return option.funcName.QualifiedName;
}

function getOptionLabel(option: autocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  if (isExistingEvent(option)) {
    return option.opt.label();
  }
  if (option.type == "selectBinary") {
    // We never render this options, so it should never be selected.
    throw new Error("select binary selected");
  }
  return option.funcName.QualifiedName;
}

function functionFriendlyName(fn: FunctionName): string {
  if (fn.Type) {
    return `${fn.Type}.${fn.Name}`;
  }
  return `${fn.Package}.${fn.Name}`;
}

export default function CaptureRecording(): React.JSX.Element {
  const client = useApolloClient();
  const navigate = useNavigate();
  const spec = useContext(SpecContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramUpdaters = makeParamUpdaters();
  const {
    processSelection: processSelectionFromURL,
    selectedEvents,
    logDuration,
    captureSnapshot,
    captureExecutionTrace,
    pprofAddresses,
    lineEvents: urlLineProbes,
    selectedLineProbes: urlSelectedLineProbes,
  } = stateFromURL(searchParams, paramUpdaters);

  const [processSelection, agentReport, binariesForSelection] =
    useProcessSelection(processSelectionFromURL);
  const [selectedBinaryID, setSelectedBinaryID] = useState<string | undefined>(
    undefined,
  );
  const binaryID =
    selectedBinaryID ??
    // If there is a single binary matching the current selection, use it.
    (binariesForSelection.length == 1 ? binariesForSelection[0] : undefined);

  const tree: EventsTree = useMemo(() => specToTree(spec), [spec]);
  const [filterInputValue, setFilterInputValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState<
    autocompleteOption | string | null
  >(null);
  const [matchingFunctions, setMatchingFunctions] = useState<FunctionName[]>(
    [],
  );
  const [expandedNodeIDs, setExpandedNodeIDs] = useState<string[]>([]);
  const [durationError, setDurationError] = useState<boolean>(false);
  const [traceInProgress, setTraceInProgress] = useState<boolean>(false);

  const [newLineProbeSelectedFilePath, setNewLineProbeSelectedFilePath] =
    useState(null as string | null);
  const [newLineProbeFilePathInput, setNewLineProbeFilePathInput] =
    useState("");
  type FileLineOption = {
    line: number;
    function: FunctionName;
  };
  const [newLineProbeLineNumberValue, setNewLineProbeLineNumberValue] =
    useState<FileLineOption | null>(null);
  const [newLineProbeLineNumberOptions, setNewLineProbeLineNumberOptions] =
    useState<FileLineOption[] | null>(null);

  const newLineEventSelectFilePath = (path: string | null) => {
    setNewLineProbeSelectedFilePath(path);
    if (path) {
      debouncedListFileLinesFromBinary(
        client,
        binaryID!,
        path,
        (results: FunctionLines[]) => {
          setNewLineProbeLineNumberOptions(
            results
              .flatMap((f: FunctionLines) => {
                return f.Lines.map((line) => {
                  return {
                    line,
                    function: f.Function,
                  } as FileLineOption;
                });
              })
              .sort((a, b) => a.line - b.line),
          );
        },
      );
    } else {
      setNewLineProbeLineNumberOptions(null);
    }
  };

  const lineProbes = urlLineProbes ?? [];
  const selectedLineProbes = urlSelectedLineProbes ?? [];

  function updateSearchParams(update: UpdateSpec<typeof paramUpdaters>): void {
    setSearchParams(computeSearchParams(searchParams, update, paramUpdaters));
  }

  const pprofInformation = new ProcessPprofAddresses(
    captureExecutionTrace ?? false,
    pprofAddresses ?? {},
    (processToken: string, address: string) => {
      updateSearchParams({
        pprofAddresses: {
          ...pprofAddresses,
          [processToken]: address,
        },
      });
    },
  );

  const recordingType = new RecordingType(
    captureSnapshot ?? false,
    selectedEvents.length > 0 || selectedLineProbes.length > 0,
    pprofInformation.shouldCollect(),
  );

  const showBinarySelectionDialog = useBinarySelectionDialog();

  const existingSpecOptions: autocompleteOption[] = useMemo(
    () => specToAutocompleteSuggestions(tree),
    [tree],
  );

  async function promptForBinarySelection() {
    const binaryID = await showBinarySelectionDialog(
      undefined /* snapshotID */,
      undefined /* funcQualifiedName */,
    );
    if (binaryID == undefined) {
      return;
    }
    setSelectedBinaryID(binaryID);
  }

  const options: autocompleteOption[] = [...existingSpecOptions];
  if (binaryID) {
    options.push(
      ...matchingFunctions
        // Remove all the functions that are already in the tree.
        .filter((f: FunctionName) => !tree.findEvent(f))
        .map((fn: FunctionName) => new autocompleteOptionNewFunction(fn)),
    );
  } else {
    options.push(new autocompleteOptionSelectBinary(promptForBinarySelection));
  }

  // When the input changes, update the matching functions from the binary. We
  // do this asynchronous operation in an effect, rather than in the onChange
  // handler, because we want to be able to cancel it if the input changes
  // again.
  useEffect(() => {
    if (binaryID == undefined) {
      return;
    }
    if (filterInputValue == "") {
      setMatchingFunctions([]);
      return;
    }
    let active = true;
    debouncedListFunctionsFromBinary(
      client,
      binaryID,
      filterInputValue,
      (results: FunctionName[]) => {
        if (!active) {
          return; // The operation was cancelled.
        }
        setMatchingFunctions(results);
      },
    );
    return () => {
      active = false;
    };
  }, [filterInputValue, binaryID, client]);

  // Synchronize the resulting processSelection with the URL. We need to do this
  // in an effect; we cannot call updateSearchParams directly from the render.
  useEffect(() => {
    // If the agent report is not available yet, don't update the URL.
    if (agentReport == undefined) {
      return;
    }
    if (
      (processSelection == undefined) !=
        (processSelectionFromURL == undefined) ||
      (processSelection != undefined &&
        processSelectionFromURL != undefined &&
        JSON.stringify(processSelection.toRaw()) !=
          JSON.stringify(processSelectionFromURL))
    ) {
      updateSearchParams({processSelection: processSelection?.toRaw()});
    }
  });

  const durationSeconds = logDuration ?? 5;

  // Filter the events according to the filter values. Note that expandedNodeIDs
  // has already been adjusted on filter changes.
  let filteredTree: EventsTree;
  if (filterValue == null) {
    filteredTree = tree;
  } else {
    if (typeof filterValue == "string" || isExistingEvent(filterValue)) {
      const filterRes = tree.filter(filterValue);
      filteredTree = filterRes.filtered;
    } else {
      filteredTree = tree;
    }
  }

  async function onCapture(): Promise<void> {
    if (processSelection == undefined) {
      throw new Error("no processes selected");
    }

    const [env, selection] = processSelectionToGraph(processSelection.toRaw());
    setTraceInProgress(true);

    try {
      const {data, errors} = await client.mutate({
        mutation: CAPTURE_RECORDING,
        variables: {
          input: {
            durationSeconds,
            environment: env == UNNAMED_ENV ? null : env,
            selections: selection,
            eventsSpec: recordingType.eventLogs
              ? {
                  probeModulePackagePaths: selectedEvents
                    .filter((group) => group.type == "module")
                    .map((m) => m.pkgPath),
                  probePackages: selectedEvents
                    .filter((group) => group.type == "pkg")
                    .map((p) => p.packageName),
                  probeTypes: selectedEvents
                    .filter((group) => group.type == "type")
                    .map((t) => t.typeName),
                  probeFuncs: selectedEvents
                    .filter((group) => group.type == "func")
                    .map((f) => f.funcQualifiedName),
                  probeLines: selectedLineProbes.map(([func, line]) => {
                    const ev = findEvent(lineProbes, func, line);
                    if (ev == undefined) {
                      throw new Error("line event not found");
                    }
                    return lineEventToLineSpec(ev);
                  }),
                }
              : undefined,
            snapshotSpec: recordingType.snapshots
              ? {
                  captureSnapshot: true,
                }
              : undefined,
            executionTraceSpec: recordingType.executionTraces
              ? {
                  addressOverrides: pprofInformation
                    .validURLs()
                    .map(({processToken, url}) => ({
                      processToken,
                      address: url,
                    })),
                }
              : undefined,
          },
        },
      });
      if (errors) {
        // This was not supposed to happen; the error was supposed to be thrown.
        // See:
        // https://community.apollographql.com/t/usesuspensequery-and-query-errors-confusion/6957/5?u=andreimatei
        throw errors;
      }

      const {recordingID, eventLogID, snapshotID} = data!.captureRecording;
      if (eventLogID) {
        toast("Events collection in progress.");
        navigate(`/recordings/${recordingID}/live-log/${eventLogID}`);
      } else if (snapshotID) {
        navigate(`/snapshots/${snapshotID}`);
      } else {
        toast("Recording in progress.");
      }
    } catch (e) {
      toastError(e);
    }
    setTraceInProgress(false);
  }

  async function addFunctionToSpec(fn: FunctionName) {
    const newSpec = await addOrUpdateFunctionSpec(
      client,
      {
        funcQualifiedName: fn.QualifiedName,
        functionStartEvent: {
          // Default the message to the function name.
          message: funcOrMethodNameWithShortPkg(fn),
        },
      },
      undefined /* showConfirmationDialog - no validation needed for empty specs */,
    );
    if (!newSpec) {
      return;
    }

    // Find the event corresponding to the new function and filter the tree to it.
    const tree = specToTree(newSpec);
    const foundEvent = tree.findEvent(fn);
    if (foundEvent == undefined) {
      throw new Error(`cannot find event for ${fn.QualifiedName}`);
    }
    const [_newEvent, module] = foundEvent;
    const autocompleteOpt = new autocompleteOptionExistingEventWrapper(
      new functionAutocompletionOption(fn),
      module.moduleName,
    );
    setFilterValue(autocompleteOpt);
    const filterRes = tree.filter(autocompleteOpt);

    // Also expand the ancestors the newly-selected node.
    const nodes: TreeNode[] = tree.modules.map(TreeNode.fromModule);
    const expandedNodeIDs = new Set<string>(filterRes.matchingNodesIDs);
    for (const n of nodes) {
      n.addAncestors(expandedNodeIDs);
    }
    setExpandedNodeIDs(Array.from(expandedNodeIDs));
    return;
  }

  function toggleLineEvent(
    checked: boolean,
    funcName: FunctionName,
    line: number,
  ) {
    let newLineEvents = selectedLineProbes ?? [];
    if (checked) {
      if (
        newLineEvents.find(
          ([evFuncQualifiedName, evLine]) =>
            evFuncQualifiedName == funcName.QualifiedName && evLine == line,
        ) != undefined
      ) {
        throw new Error("line probe already selected");
      }

      newLineEvents.push([funcName.QualifiedName, line]);
    } else {
      if (
        newLineEvents.find(
          ([evFuncName, evLine]) =>
            evFuncName == funcName.QualifiedName && evLine == line,
        ) == undefined
      ) {
        throw new Error("line probe not selected");
      }

      newLineEvents = newLineEvents.filter(
        ([evFuncName, evLine]) =>
          evFuncName != funcName.QualifiedName || evLine != line,
      );
    }
    updateSearchParams({selectedLineProbes: Array.from(newLineEvents)});
  }

  function addLineProbe(file: string, func: FunctionName, line: number) {
    if (
      lineProbes.find(
        (ev) =>
          ev.file == file &&
          ev.function.QualifiedName == func.QualifiedName &&
          ev.line == line,
      ) != undefined
    ) {
      // Event already exists; nothing to do.
      return;
    }
    lineProbes.push({file, function: func, line, eventSpec: {exprs: []}});
    // The new event start off as selected.
    selectedLineProbes.push([func.QualifiedName, line]);
    updateSearchParams({
      lineEvents: lineProbes,
      selectedLineProbes: selectedLineProbes,
    });
  }

  return (
    <>
      <Box my={3}>
        <Typography variant="h1">Capture Recording</Typography>
        <Typography variant="body3" color="primary.light">
          Choose what to include in the recording. If probes are selected, once
          the recording begins the generated events will be streamed, as well as
          saved for later analysis.
        </Typography>
      </Box>

      <Stack gap={3}>
        <Card>
          <CardHeader title="Select processes from which to capture" />
          <CardContent>
            <ProcessSelector
              selection={processSelection}
              agentReports={agentReport}
              onSelectionUpdated={(newSelection) => {
                updateSearchParams({processSelection: newSelection});
              }}
              pprofInformation={pprofInformation}
            />
            {(processSelection == undefined || processSelection.empty()) && (
              <Typography variant={"error"}>Selection required</Typography>
            )}
          </CardContent>
        </Card>
        <Card>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CardHeader title="Capture snapshot" />
            <Switch
              checked={captureSnapshot}
              onChange={(event) => {
                updateSearchParams({captureSnapshot: event.target.checked});
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CardHeader title="Capture execution trace and CPU profile" />
            <Switch
              checked={captureExecutionTrace}
              onChange={(event) => {
                updateSearchParams({
                  captureExecutionTrace: event.target.checked,
                });
              }}
            />
          </Stack>
        </Card>
        <Card>
          <CardHeader
            title="Select probes to enable"
            subheader="Events will be generated by the selected probes."
          />
          <CardContent>
            <Stack direction="row" alignItems="center" gap={1}>
              <Autocomplete
                fullWidth
                options={options}
                // The user can also type any string to filter the events in the
                // tree.
                freeSolo={true}
                // The selectBinary option is treated as disabled; we don't want
                // the user to be able to select it; we only want them to
                // interact with it through the button that it renders.
                getOptionDisabled={(opt) => opt.type == "selectBinary"}
                groupBy={(option: autocompleteOption) => {
                  if (
                    option instanceof autocompleteOptionExistingEventWrapper
                  ) {
                    return "Existing probes";
                  }
                  return "Other matching functions";
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Filter probes" />
                )}
                // We take control over the filtering process so that we can do our
                // own matching of the query to the suggestions. Also, the suggestions
                // change in response to the query -- parts of the string get
                // highlighted. Also, some suggestions (the package prefixes) are
                // generated based on the query.
                filterOptions={(options, state) =>
                  matchAutocompleteOptions(options, state.inputValue)
                }
                renderOption={(props, opt: autocompleteOption) => {
                  if (isExistingEvent(opt)) {
                    return opt.opt.render(props);
                  }
                  return opt.render(props);
                }}
                getOptionKey={getOptionKey}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(_, value: string | autocompleteOption | null) => {
                  if (value == null) {
                    setExpandedNodeIDs([]);
                    setFilterValue(value);
                    return;
                  }
                  // If the user selected an existing event, or simply typed a
                  // string to use for filtering, find the matching nodes and
                  // expand their ancestors.
                  if (typeof value == "string" || isExistingEvent(value)) {
                    const filterRes = tree.filter(value);

                    // Also expand the ancestors of any node in expandedNodeIDs.
                    const nodes: TreeNode[] = tree.modules.map(
                      TreeNode.fromModule,
                    );
                    const expandedNodeIDs = new Set<string>(
                      filterRes.matchingNodesIDs,
                    );
                    for (const n of nodes) {
                      n.addAncestors(expandedNodeIDs);
                    }
                    setExpandedNodeIDs(Array.from(expandedNodeIDs));
                    setFilterValue(value);
                    return;
                  }

                  if (value.type == "selectBinary") {
                    throw new Error(
                      "bug: the select binary options was not supposed to be selectable",
                    );
                  }

                  // A new function was selected. Add it to the spec.
                  void addFunctionToSpec(value.funcName);
                  setFilterValue(value);
                }}
                value={filterValue}
                inputValue={filterInputValue}
                onInputChange={(_event, value) => setFilterInputValue(value)}
              />
              <HelpCircle
                tip={`Filter the events by function name, type, package or module.`}
              />
            </Stack>
            <Box sx={{mt: 2}}>
              <EventsTreeView
                tree={filteredTree}
                noEventsMessage={
                  tree.modules.length == 0
                    ? "No events defined."
                    : "No events match."
                }
                expandedNodeIDs={expandedNodeIDs}
                setExpandedNodeIDs={setExpandedNodeIDs}
                checkedNodes={selectedEvents}
                setCheckedNodes={(
                  eventGroupSelections: EventsGroupSelection[],
                ) => {
                  updateSearchParams({selectedEvents: eventGroupSelections});
                }}
                binaryID={binaryID ?? (() => void promptForBinarySelection())}
              />
            </Box>
            {!recordingType.something() && (
              <Typography variant={"error"}>Selection required</Typography>
            )}
            <Box sx={{mt: 2}}>
              <Typography>Line probes</Typography>
              <List>
                {lineProbes?.map((ev, index) => (
                  <ListItem key={index}>
                    <LineProbeItem
                      file={ev.file}
                      funcName={ev.function}
                      lineNumber={ev.line}
                      eventSpec={lineEventToEventSpec(ev)}
                      selected={
                        (selectedLineProbes ?? []).find(
                          ([selFuncName, selLine]) =>
                            selFuncName == ev.function.QualifiedName &&
                            selLine == ev.line,
                        ) != undefined
                      }
                      onSelectedChange={(funcName, line, checked) =>
                        toggleLineEvent(checked, funcName, line)
                      }
                      onExprAdded={(
                        func: FunctionName,
                        line: number,
                        expr: string,
                      ) => {
                        const newLineEvents = lineProbes.map((ev) =>
                          ev.function.QualifiedName == func.QualifiedName &&
                          ev.line == line
                            ? addExprToEvent(ev, expr)
                            : ev,
                        );
                        updateSearchParams({lineEvents: newLineEvents});
                      }}
                      onExprRemoved={(
                        func: FunctionName,
                        line: number,
                        expr: string,
                      ) => {
                        const newLineEvents = lineProbes.map((ev) =>
                          ev.function.QualifiedName == func.QualifiedName &&
                          ev.line == line
                            ? removeExprFromEvent(ev, expr)
                            : ev,
                        );
                        updateSearchParams({lineEvents: newLineEvents});
                      }}
                      binaryID={binaryID}
                    />
                  </ListItem>
                ))}
              </List>

              {binaryID != undefined ? (
                <Stack direction="row" gap={2} alignItems={"center"}>
                  <Typography noWrap={true}>Add new line probe</Typography>
                  <FileAutocomplete
                    sx={{flexGrow: 1}}
                    binaryID={binaryID}
                    inputValue={newLineProbeFilePathInput}
                    value={newLineProbeSelectedFilePath}
                    onValueChange={(val) => newLineEventSelectFilePath(val)}
                    onInputChange={(val) => setNewLineProbeFilePathInput(val)}
                  />
                  <Autocomplete
                    sx={{flexGrow: 1}}
                    disablePortal
                    disabled={newLineProbeLineNumberOptions == null}
                    options={newLineProbeLineNumberOptions ?? []}
                    filterOptions={(o, s) => {
                      if (s.inputValue == "" || o.length == 0) {
                        return o;
                      }
                      const inputLine = Number.parseInt(s.inputValue);
                      const idx = o.findLastIndex((fl) => fl.line <= inputLine);
                      if (idx == -1) {
                        return [o[0]];
                      }
                      if (o[idx].line == inputLine || idx == o.length - 1) {
                        return [o[idx]];
                      }
                      return [o[idx], o[idx + 1]];
                    }}
                    groupBy={(option) => functionFriendlyName(option.function)}
                    // !!! renderGroup={(params) => params.group}
                    getOptionLabel={(option) => option.line.toString()}
                    value={newLineProbeLineNumberValue}
                    onChange={(_e, newValue) => {
                      setNewLineProbeLineNumberValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          newLineProbeLineNumberValue
                            ? functionFriendlyName(
                                newLineProbeLineNumberValue.function,
                              )
                            : "line number"
                        }
                      />
                    )}
                  />
                  <Tooltip title={"Add new file:line probe"}>
                    <span>
                      <Button
                        disabled={
                          newLineProbeSelectedFilePath == null ||
                          newLineProbeLineNumberValue == null
                        }
                        onClick={() => {
                          addLineProbe(
                            newLineProbeSelectedFilePath!,
                            newLineProbeLineNumberValue!.function,
                            newLineProbeLineNumberValue!.line,
                          );
                        }}
                        color="info"
                        variant="outlined"
                      >
                        <AddIcon />
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
              ) : (
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <Typography>Select binary to add line probes</Typography>
                  <SelectorBinary setBinaryID={setSelectedBinaryID} />
                </Stack>
              )}
            </Box>
          </CardContent>
        </Card>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography>Enable recording for</Typography>
          <TextField
            disabled={
              recordingType.something() && !recordingType.durationRecording()
            }
            sx={{width: "4em"}}
            color="secondary"
            value={durationSeconds}
            error={durationError}
            helperText={durationError ? "Invalid duration" : ""}
            onChange={(e) => {
              const valStr = e.target.value;
              const val = parseInt(valStr);
              if (isNaN(val)) {
                setDurationError(true);
              } else {
                setDurationError(false);
                updateSearchParams({logDuration: val});
              }
            }}
          />
          <Typography>seconds.</Typography>
        </Stack>
        <LoadingButton
          sx={{width: "fit-content"}}
          loadingPosition={"start"}
          startIcon={<SaveIcon />}
          loading={traceInProgress}
          variant={"contained"}
          onClick={() => void onCapture()}
          onAuxClick={() => void onCapture()}
          disabled={
            processSelection == undefined ||
            processSelection.empty() ||
            !recordingType.something() ||
            durationError
          }
        >
          {recordingType.recordingButtonLabel()}
        </LoadingButton>
      </Stack>
    </>
  );
}

function isOptionEqualToValue(
  opt: autocompleteOption,
  value: autocompleteOption | string,
): boolean {
  if (typeof value == "string") {
    return false;
  }

  if (isExistingEvent(opt) != isExistingEvent(value)) {
    return false;
  }

  if (isExistingEvent(opt)) {
    if (!isExistingEvent(value)) {
      return false;
    }
    return opt.opt.equals(value.opt);
  }

  if (isExistingEvent(value)) {
    return false;
  }

  if (opt.type == "selectBinary" || value.type == "selectBinary") {
    // The selectBinary option can never be selected.
    return false;
  }

  return opt.funcName.QualifiedName == value.funcName.QualifiedName;
}

type nodeStatus = "all" | "some" | "none";

function computeBottomUpStatus(
  n: TreeNode,
  parentChecked: boolean,
  checkedNodeIDs: string[],
  m: Map<string, nodeStatus>,
) {
  const nodeCheckedExplicitly = checkedNodeIDs.includes(n.id);
  for (const c of n.children) {
    computeBottomUpStatus(
      c,
      parentChecked || nodeCheckedExplicitly,
      checkedNodeIDs,
      m,
    );
  }
  if (parentChecked || nodeCheckedExplicitly) {
    m.set(n.id, "all");
    return;
  }
  if (n.children.length == 0) {
    m.set(n.id, "none");
    return;
  }
  if (n.children.every((c) => m.get(c.id) == "all")) {
    m.set(n.id, "all");
  } else if (
    n.children.some((c) => {
      const childStatus = m.get(c.id);
      return childStatus == "some" || childStatus == "all";
    })
  ) {
    m.set(n.id, "some");
  } else {
    m.set(n.id, "none");
  }
}

function linkParents(node: TreeNode, parent: TreeNode | undefined) {
  node.parent = parent;
  for (const c of node.children) {
    linkParents(c, node);
  }
}

// EventsTreeView renders a tree with all functions that have events specs,
// together with the modules, packages and types parents.
function EventsTreeView(props: {
  tree: EventsTree;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  checkedNodes: EventsGroupSelection[];
  setCheckedNodes: (checkedNodes: EventsGroupSelection[]) => void;
  // noEventsMessage is the message rendered if tree is empty.
  noEventsMessage: string;

  // binaryID is either the ID of the binary to be used when listing available
  // variables, or a function to call to get the binary ID. If it is a function,
  // the expectation is that calling the function will eventually cause a
  // re-render of this component with the binary ID set to a string.
  binaryID: string | (() => void);
}): React.JSX.Element {
  const nodes: TreeNode[] = props.tree.modules.map(TreeNode.fromModule);
  for (const n of nodes) {
    linkParents(n, undefined /* parent */);
  }
  const nodeStatuses = new Map<string, nodeStatus>();
  for (const n of nodes) {
    computeBottomUpStatus(
      n,
      false /* parentChecked */,
      props.checkedNodes.map((n) => n.nodeID()),
      nodeStatuses,
    );
  }

  function toggleNode(node: TreeNode, checked: boolean) {
    let newCheckedNodes: EventsGroupSelection[];
    if (checked) {
      newCheckedNodes = [...props.checkedNodes];
      newCheckedNodes.push(node.events.toSelection());
    } else {
      // First, uncheck the node and all its children, recursively.
      const childIDs = node.treeIDs();
      newCheckedNodes = props.checkedNodes.filter((n) => {
        return !childIDs.includes(n.nodeID());
      });

      const fixup = () => {
        if (node.parent == undefined) {
          return;
        }
        let parent: TreeNode = node.parent;
        while (parent != undefined) {
          if (props.checkedNodes.some((n) => n.nodeID() == parent.id)) {
            // I've found a parent that was checked. Uncheck it, and check all
            // its children except the child through which we got here.
            newCheckedNodes = props.checkedNodes.filter(
              (n) => n.nodeID() != parent.id,
            );

            for (const c of parent.children) {
              if (c.id == node.id) {
                // Ignore our node.
                continue;
              }
              const childChecked = props.checkedNodes.some(
                (n) => n.nodeID() == c.id,
              );
              if (!childChecked) {
                newCheckedNodes.push(c.events.toSelection());
              }
            }
          }
          if (parent.parent == undefined) {
            return;
          }
          parent = parent.parent;
          node = node.parent!;
        }
      };

      // Now walk to the root and, whenever we find a node that's checked, we
      // replace it with all its children except the child through which we
      // got there.
      fixup();
    }

    props.setCheckedNodes(newCheckedNodes);
  }

  // A callback to hide the variables for the node that is currently showing
  // variables, if any.
  const [hidePreviousVars, setHidePreviousVars] = useState<
    (() => void) | undefined
  >(undefined);

  // onNodeVarsToggled is called whenever the vars of a node are toggled. It ensures that
  // only one node's variables are toggled at a time.
  function onNodeVarsToggled(hide: (() => void) | undefined) {
    if (hidePreviousVars != undefined) {
      hidePreviousVars();
    }
    setHidePreviousVars(() => hide);
  }

  return (
    <SimpleTreeView
      disableSelection={true}
      slots={{expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon}}
      expandedItems={props.expandedNodeIDs}
      onExpandedItemsChange={(_event, expandedNodeIDs: string[]) => {
        props.setExpandedNodeIDs(expandedNodeIDs);
      }}
    >
      {nodes.length == 0 ? (
        <Box key={"no-fields"}>
          <Typography variant={"explanation"}>
            {props.noEventsMessage}
          </Typography>
        </Box>
      ) : (
        <>
          {nodes.map((n) => (
            <EventsTreeNode
              key={n.id}
              node={n}
              nodeStatuses={nodeStatuses}
              expandedNodeIDs={props.expandedNodeIDs}
              setExpandedNodeIDs={props.setExpandedNodeIDs}
              toggleNode={toggleNode}
              onVarsToggle={(hide: (() => void) | undefined) =>
                onNodeVarsToggled(hide)
              }
              binaryID={props.binaryID}
            />
          ))}
        </>
      )}
    </SimpleTreeView>
  );
}

type EventsGroupSelection =
  | ModuleEventsSelection
  | PackageEventsSelection
  | TypeEventsSelection
  | EventInfoSelection;

class ModuleEventsSelection {
  type = "module" as const;
  // The prefix of the package path that defines this module.
  pkgPath: string;

  constructor(pkgPath: string) {
    this.pkgPath = pkgPath;
  }

  nodeID = (): string => {
    return `mod:${this.pkgPath}`;
  };
}

class PackageEventsSelection {
  type = "pkg" as const;
  packageName: string;

  constructor(packageName: string) {
    this.packageName = packageName;
  }

  nodeID = (): string => {
    return `pkg:${this.packageName}`;
  };
}

class TypeEventsSelection {
  type = "type" as const;
  typeName: string;
  pkgName: string;

  constructor(typeName: string, pkgName: string) {
    this.typeName = typeName;
    this.pkgName = pkgName;
  }

  nodeID = (): string => {
    return `pkg:${this.pkgName}:type:${this.typeName}`;
  };
}

class EventInfoSelection {
  type = "func" as const;
  funcQualifiedName: string;

  constructor(funcQualifiedName: string) {
    this.funcQualifiedName = funcQualifiedName;
  }

  nodeID = (): string => {
    return `func:${this.funcQualifiedName}`;
  };
}

// EventsGroups represents a group of event specs (or a single event) that can
// be selected for inclusion in a log.
type EventsGroup = ModuleEvents | PackageEvents | TypeEvents | EventInfo;

class TreeNode {
  type: "module" | "package" | "type" | "function";
  events: EventsGroup;
  name: string;
  children: TreeNode[];
  parent: TreeNode | undefined;
  // A unique ID for this node, as the TreeItem component wants.
  id: string;

  constructor(
    type: "module" | "package" | "type" | "function",
    events: EventsGroup,
    name: string,
    children: TreeNode[],
    id: string,
  ) {
    this.type = type;
    this.events = events;
    this.name = name;
    this.children = children;
    this.id = id;
  }

  static fromModule = (module: ModuleEvents): TreeNode => {
    return new TreeNode(
      "module",
      module,
      module.moduleName,
      module.packages.map(TreeNode.fromPackage),
      module.nodeID(),
    );
  };

  static fromPackage = (pkg: PackageEvents): TreeNode => {
    return new TreeNode(
      "package",
      pkg,
      pkg.packageName,
      [
        ...pkg.types.map(TreeNode.fromType),
        ...pkg.functionEvents.map(TreeNode.fromEvent),
      ],
      pkg.nodeID(),
    );
  };

  static fromType = (type: TypeEvents): TreeNode => {
    return new TreeNode(
      "type",
      type,
      type.typeName,
      type.events.map(TreeNode.fromEvent),
      type.nodeID(),
    );
  };

  static fromEvent = (func: EventInfo): TreeNode => {
    return new TreeNode("function", func, func.func.Name, [], func.nodeID());
  };

  // addAncestors takes a set of nodeIDs and adds all ancestors of the
  // respective nodes to the set. Returns true if the set was modified (if the
  // set was modified, that implies that this module was also added to the set).
  addAncestors = (nodeIDs: Set<string>): boolean => {
    let anyAdded = false;
    for (const n of this.children) {
      const added = n.addAncestors(nodeIDs);
      anyAdded = anyAdded || added;
    }
    let modified = false;
    if (anyAdded || nodeIDs.has(this.id)) {
      modified = true;
      nodeIDs.add(this.id);
    }
    return modified;
  };

  treeIDs(): string[] {
    const ids: string[] = [this.id];
    for (const c of this.children) {
      ids.push(c.id);
      ids.push(...c.treeIDs());
    }
    return ids;
  }
}

type filterResults = {
  filtered: EventsTree;
  matchingNodesIDs: string[];
};

class EventsTree {
  modules: ModuleEvents[];

  constructor(modules: ModuleEvents[] = []) {
    this.modules = modules;
  }

  addFunctionStartEvent = (
    event: FunctionStartEventSpec,
    moduleName: string,
    modulePkgPath: string,
    functionSpec: FunctionSpec,
  ) => {
    let module = this.modules.find((m) => m.moduleName == moduleName);
    if (!module) {
      const newModule = new ModuleEvents(moduleName, modulePkgPath, []);
      this.modules.push(newModule);
      module = newModule;
    }
    let pkg = module.packages.find(
      (p) => p.packageName == functionSpec.funcName.Package,
    );
    if (!pkg) {
      const newPkg = new PackageEvents(functionSpec.funcName.Package, [], []);
      module.packages.push(newPkg);
      pkg = newPkg;
    }

    if (functionSpec.funcName.Type) {
      let type = pkg.types.find(
        (t) => t.typeName == functionSpec.funcName.Type,
      );
      if (!type) {
        const newType = new TypeEvents(
          functionSpec.funcName.Type,
          pkg.packageName,
          [],
        );
        pkg.types.push(newType);
        type = newType;
      }
      type.events.push(new EventInfo(event, functionSpec.funcName));
    } else {
      pkg.functionEvents.push(new EventInfo(event, functionSpec.funcName));
    }
  };

  // filter takes a selected autocomplete option or a string, and returns the
  // filtered tree containing the nodes that match the query and their children.
  // If the query is a string, all nodes that contain that string are matches.
  filter = (
    opt: autocompleteOptionExistingEventWrapper | string,
  ): filterResults => {
    if (typeof opt == "string") {
      const newModules: ModuleEvents[] = [];
      const matchingNodeIDs: string[] = [];
      for (const e of this.modules) {
        const res = e.filter(opt);
        if (res) {
          newModules.push(res.filtered);
          matchingNodeIDs.push(...res.matchingNodeIDs);
        }
      }

      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const module = this.modules.find((m) => m.moduleName == opt.moduleName);
    if (module == undefined) {
      throw new Error(`Module ${opt.moduleName} not found`);
    }

    const matchingNodeIDs = [module.nodeID()];

    const inner = opt.opt;

    if (inner.type == "module") {
      return {
        filtered: new EventsTree([module]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "packagePrefix") {
      const newModules: ModuleEvents[] = [];
      for (const m of this.modules) {
        const newPackages: PackageEvents[] = [];
        for (const p of m.packages) {
          if (p.packageName.startsWith(inner.pkg)) {
            newPackages.push(p);
            matchingNodeIDs.push(p.nodeID());
          }
        }

        if (newPackages.length > 0) {
          newModules.push(
            new ModuleEvents(m.moduleName, m.pkgPath, newPackages),
          );
          matchingNodeIDs.push(m.nodeID());
        }
      }
      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const pkgName =
      inner.type != "function" ? inner.pkgName : inner.function.Package;
    const pkg = module.packages.find((p) => p.packageName == pkgName);
    if (pkg == undefined) {
      throw new Error(`Package ${pkgName} not found`);
    }
    matchingNodeIDs.push(pkg.nodeID());

    if (inner.type == "package") {
      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [pkg]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "type") {
      const type = pkg.types.find((t) => t.typeName == inner.typeName);
      if (type == undefined) {
        throw new Error(
          `Type ${inner.typeName} not found in package ${pkgName}`,
        );
      }
      matchingNodeIDs.push(type.nodeID());

      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [
            new PackageEvents(pkgName, [type], []),
          ]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "function") {
      const typeName = inner.function.Type;
      if (typeName) {
        const type = pkg.types.find((t) => t.typeName == typeName);
        if (type == undefined) {
          throw new Error(`Type ${typeName} not found in package ${pkgName}`);
        }
        matchingNodeIDs.push(type.nodeID());

        const func = type.events.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in type ${typeName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());

        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(
                pkgName,
                [new TypeEvents(typeName, pkgName, [func])],
                [],
              ),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      } else {
        // The function is not a method.
        const func = pkg.functionEvents.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in package ${pkgName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());
        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(pkgName, [], [func]),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      }
    }
    exhaustiveCheck(inner);
  };

  // Find the event corresponding to a particular function, if any.
  findEvent = (
    funcName: FunctionName,
  ): [EventInfo, ModuleEvents] | undefined => {
    for (const m of this.modules) {
      const e = m.findEvent(funcName);
      if (e) {
        return [e, m];
      }
    }
    return undefined;
  };
}

class ModuleEvents {
  type = "module" as const;
  moduleName: string;
  // The prefix of the package path that defines this module.
  pkgPath: string;
  packages: PackageEvents[];

  constructor(moduleName: string, pkgPath: string, packages: PackageEvents[]) {
    this.moduleName = moduleName;
    this.pkgPath = pkgPath;
    this.packages = packages;
  }

  filter = (
    query: string,
  ): {filtered: ModuleEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.moduleName.includes(query)) {
      // TODO(andrei): Also test the query against the children, recursively, to
      // collect more matchingNodeIDs?
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newPkgs: PackageEvents[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.packages) {
      const res = e.filter(query);
      if (res) {
        newPkgs.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }

    if (newPkgs.length > 0) {
      return {
        filtered: new ModuleEvents(this.moduleName, this.pkgPath, newPkgs),
        matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): ModuleEventsSelection => {
    return new ModuleEventsSelection(this.pkgPath);
  };

  // Find the event corresponding to a particular function, if any.
  findEvent = (funcName: FunctionName): EventInfo | undefined => {
    const pkg = this.packages.find((p) => p.packageName == funcName.Package);
    if (pkg == undefined) {
      return undefined;
    }
    return pkg.findEvent(
      funcName.Type == "" ? undefined : funcName.Type,
      funcName.Name,
    );
  };
}

class PackageEvents {
  type = "pkg" as const;
  packageName: string = "";
  types: TypeEvents[] = [];
  // Events corresponding to functions in this package (as opposed to methods on
  // types in this package, which are stored in `types`).
  functionEvents: EventInfo[] = [];

  constructor(
    packageName: string,
    types: TypeEvents[],
    functionEvents: EventInfo[],
  ) {
    this.packageName = packageName;
    this.types = types;
    this.functionEvents = functionEvents;
  }

  filter = (
    query: string,
  ): {filtered: PackageEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.packageName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const matchingNodeIDs: string[] = [this.nodeID()];
    const newFunctionEvents: EventInfo[] = [];
    for (const e of this.functionEvents) {
      const res = e.filter(query);
      if (res) {
        newFunctionEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    const newTypes: TypeEvents[] = [];
    for (const t of this.types) {
      const res = t.filter(query);
      if (res) {
        newTypes.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newTypes.length > 0 || newFunctionEvents.length > 0) {
      return {
        filtered: new PackageEvents(
          this.packageName,
          newTypes,
          newFunctionEvents,
        ),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): PackageEventsSelection => {
    return new PackageEventsSelection(this.packageName);
  };

  // Find the event corresponding to a particular function.
  findEvent = (
    typeName: string | undefined,
    funcName: string,
  ): EventInfo | undefined => {
    if (typeName) {
      const type = this.types.find((t) => t.typeName == typeName);
      if (type == undefined) {
        return undefined;
      }
      return type.findEvent(funcName);
    }
    return this.functionEvents.find((e) => e.func.Name == funcName);
  };
}

class TypeEvents {
  type = "type" as const;
  typeName: string = "";
  pkgName: string = "";
  events: EventInfo[] = [];

  constructor(typeName: string, pkgName: string, events: EventInfo[]) {
    this.typeName = typeName;
    this.pkgName = pkgName;
    this.events = events;
  }

  filter = (
    query: string,
  ): {filtered: TypeEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.typeName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newEvents: EventInfo[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.events) {
      const res = e.filter(query);
      if (res) {
        newEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newEvents.length > 0) {
      return {
        filtered: new TypeEvents(this.typeName, this.pkgName, newEvents),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): TypeEventsSelection => {
    return new TypeEventsSelection(this.typeName, this.pkgName);
  };

  // Find the event corresponding to a particular function/method name. Note
  // that funcName is NOT a fully-qualified name.
  findEvent = (funcName: string): EventInfo | undefined => {
    return this.events.find((e) => e.func.Name == funcName);
  };
}

class EventInfo {
  type = "func" as const;
  spec: FunctionStartEventSpec;
  func: FunctionName;

  constructor(spec: FunctionStartEventSpec, func: FunctionName) {
    this.spec = spec;
    this.func = func;
  }

  filter = (
    query: string,
  ): {filtered: EventInfo; matchingNodeIDs: string[]} | undefined => {
    if (this.func.Name.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): EventInfoSelection => {
    return new EventInfoSelection(this.func.QualifiedName);
  };
}

function specToTree(spec: FullSnapshotSpecFragment): EventsTree {
  const eventsTree = new EventsTree();
  const mm = spec.modules;
  for (let i = 0; i < mm.length; i++) {
    const module = spec.modules[i];
    for (const func of module.functionSpecs) {
      if (func.functionStartEvent) {
        eventsTree.addFunctionStartEvent(
          func.functionStartEvent,
          module.name,
          module.pkgPath,
          func,
        );
      }
    }
  }
  return eventsTree;
}

function EventsTreeNode(props: {
  node: TreeNode;
  nodeStatuses: Map<string, nodeStatus>;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  toggleNode: (node: TreeNode, checked: boolean) => void;
  // onVarsToggle is called when the user clicks the "Show/hide captured
  // variables" button. If the node's variables are toggled to shown, then
  // `hide` is a callback that can be used to hide the variables. If the node's
  // variables are toggled to hidden, then `hide` is undefined. EventsTreeNode
  // renders the function's variables on its own, but the parent might still
  // want to know that some variables are being shown.
  onVarsToggle: (hide: (() => void) | undefined) => void;

  // binaryID is either the ID of the binary to be used when listing available
  // variables, or a function to call to get the binary ID. If it is a function,
  // the expectation is that calling the function will eventually cause a
  // re-render of this component with the binary ID set to a string.
  binaryID: string | (() => void);
}): React.JSX.Element {
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();
  const [showVars, setShowVarsInner] = useState(false);
  const setShowVars = (val: boolean) => {
    setShowVarsInner(val);
    if (val) {
      props.onVarsToggle(() => setShowVarsInner(false));
    } else {
      props.onVarsToggle(undefined);
    }
  };

  const onNodeToggle = (checked: boolean, node: TreeNode): void => {
    props.toggleNode(node, checked);
  };

  const node = props.node;
  const status = props.nodeStatuses.get(props.node.id);
  if (status == undefined) {
    throw new Error(`bottom-up status not found for node ${props.node.id}`);
  }
  const ev = node.events;
  const eventSpec = ev.type == "func" ? ev.spec : undefined;

  const eventSpecEditor =
    ev.type == "func"
      ? new FunctionSpecEditor(
          "event",
          ev.func,
          eventSpec,
          showConfirmationDialog,
          client,
        )
      : undefined;

  return (
    <TreeItem
      key={node.name}
      itemId={node.id}
      label={
        <span>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Checkbox
              checked={status == "all"}
              indeterminate={status == "some"}
              onChange={(event) => onNodeToggle(event.target.checked, node)}
              onClick={(e) => e.stopPropagation()} // Don't expand/collapse the whole TreeItem when clicking the checkbox.
            />
            {node.type == "module" && <CodeIcon />}
            {node.type == "type" && <DataArrayIcon />}
            {/*TODO: don't use the same icon for function and package */}
            {node.type == "package" && <DataObjectIcon />}
            {node.type == "function" && <DataObjectIcon />}
            <Typography>{node.name}</Typography>
            {ev.type == "func" &&
              (showVars ? (
                <Button onClick={() => setShowVars(false)}>
                  Hide captured variables
                </Button>
              ) : (
                <Button onClick={() => setShowVars(true)}>
                  Show captured variables
                </Button>
              ))}
          </Stack>
          {ev.type == "func" && showVars && (
            <FunctionTableEditor
              labels={{
                variablesLabel: "Variables included in the event",
                variablesTooltip: `The set of variables to collect and expressions to evaluate
                    whenever this function starts executing.`,
                capturedExprTooltip: `The name of the column representing this captured
                    expression in the function's events table.`,
                tableNameTooltip: `The table name under which this function's event table is stored in
                    the events database. This is the table name to use in SQL queries.`,
                extraColsTooltip: `Extra columns for the function's events table, in addition to 
                    the columns defined implicitly by the captured variables above. These 
                    extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top 
                    of the implicit columns (i.e. the expressions can reference these implicit columns; 
                    the names of implicit columns containing dots should be quoted like "myVar.myField").`,
              }}
              binaryID={props.binaryID}
              specEditor={eventSpecEditor!}
              tableSpec={eventSpec}
              funcQualifiedName={ev.func.QualifiedName}
              paramsOnly={true}
            />
          )}
        </span>
      }
    >
      {node.children.map((n: TreeNode) => (
        <EventsTreeNode
          key={n.id}
          node={n}
          nodeStatuses={props.nodeStatuses}
          toggleNode={props.toggleNode}
          onVarsToggle={props.onVarsToggle}
          expandedNodeIDs={props.expandedNodeIDs}
          setExpandedNodeIDs={props.setExpandedNodeIDs}
          binaryID={props.binaryID}
        />
      ))}
    </TreeItem>
  );
}

const PprofAddressesSchema = z.record(z.string(), z.string());

const funcNameSchema = z.object({
  Name: z.string(),
  Package: z.string(),
  QualifiedName: z.string(),
  Type: z.string(),
}) satisfies ZodType<FunctionName>;

// TODO: This should mimic FunctionStartEventSpec (i.e. have information about
// column names, etc.).
const eventSchema = z.object({
  // The list of expressions to capture.
  exprs: z.array(z.string()),
});

const lineProbeSchema = z.object({
  file: z.string(),
  line: z.number(),
  // The function containing the respective line of code.
  function: funcNameSchema,
  eventSpec: eventSchema,
});

const lineEventsSchema = z.array(lineProbeSchema);
type LineEventType = z.infer<typeof lineProbeSchema>;

function lineEventToEventSpec(ev: LineEventType): FunctionStartEventSpec {
  return {
    collectExprs: ev.eventSpec.exprs.map((expr) => ({
      expr: expr,
      column: {name: expr, type: null, hidden: false},
      opt: null,
    })),
    message: `${ev.function.QualifiedName}:${ev.line}`,
    extraColumns: [],
    tableName: `${ev.function.QualifiedName}_${ev.line}`,
  };
}

function lineEventToLineSpec(ev: LineEventType): LineSpec {
  return {
    funcQualifiedName: ev.function.QualifiedName,
    line: ev.line,
    eventSpec: lineEventToEventSpec(ev),
  };
}

function addExprToEvent(ev: LineEventType, expr: string): LineEventType {
  // If the expr already exists, there's nothing to do.
  if (ev.eventSpec.exprs.includes(expr)) {
    return ev;
  }

  return {
    ...ev,
    eventSpec: {...ev.eventSpec, exprs: [...ev.eventSpec.exprs, expr]},
  };
}

function removeExprFromEvent(ev: LineEventType, expr: string): LineEventType {
  // If the expr doesn't exist, there's nothing to do.
  if (!ev.eventSpec.exprs.includes(expr)) {
    return ev;
  }

  return {
    ...ev,
    eventSpec: {
      ...ev.eventSpec,
      exprs: ev.eventSpec.exprs.filter((e) => e != expr),
    },
  };
}

const selectedProbesSchema = z.array(z.tuple([z.string(), z.number()]));

function findEvent(
  lineEvents: LineEventType[],
  funcQualifiedName: string,
  line: number,
): LineEventType | undefined {
  return lineEvents.find(
    (ev) => ev.function.QualifiedName == funcQualifiedName && ev.line == line,
  );
}

type paramUpdaters = {
  processSelection: ZodParamUpdater<typeof ProcessSelectionJSONSchema>;
  logDuration: NumberParamUpdater;
  selectedEvents: ParamUpdater<EventsGroupSelection[], EventsGroupSelection[]>;
  captureSnapshot: BooleanParamUpdater;
  captureExecutionTrace: BooleanParamUpdater;
  pprofAddresses: ZodParamUpdater<typeof PprofAddressesSchema>;
  lineEvents: ZodParamUpdater<typeof lineEventsSchema>;
  selectedLineProbes: ZodParamUpdater<typeof selectedProbesSchema>;
};

function makeParamUpdaters(): paramUpdaters {
  const sp = new StringsParamUpdater("events");
  return {
    processSelection: new ZodParamUpdater(
      "processes",
      ProcessSelectionJSONSchema,
    ),
    logDuration: new NumberParamUpdater("logDuration"),
    selectedEvents: new ParamUpdater<
      EventsGroupSelection[],
      EventsGroupSelection[]
    >(
      "events",
      (
        _param: string,
        searchParams: URLSearchParams,
      ): EventsGroupSelection[] => {
        const events: string[] = sp.get(searchParams);
        return events.map((s) => {
          if (s.startsWith("mod:")) {
            return new ModuleEventsSelection(s.slice(4));
          }
          if (s.startsWith("pkg:") && s.includes(":type:")) {
            const parts = s.split(":");
            return new TypeEventsSelection(parts[3], parts[1]);
          }
          if (s.startsWith("pkg:")) {
            return new PackageEventsSelection(s.slice(4));
          }
          if (s.startsWith("func:")) {
            return new EventInfoSelection(s.slice(5));
          }
          throw new Error(`unexpected events selection: ${s}`);
        });
      },
      (
        _param: string,
        searchParams: URLSearchParams,
        newValue: EventsGroupSelection[],
      ) => {
        return sp.update(
          searchParams,
          newValue.map((e: EventsGroupSelection): string => e.nodeID()),
        );
      },
    ),
    captureSnapshot: new BooleanParamUpdater("captureSnapshot", true),
    captureExecutionTrace: new BooleanParamUpdater("captureExecutionTrace"),
    pprofAddresses: new ZodParamUpdater("pprofAddresses", PprofAddressesSchema),
    lineEvents: new ZodParamUpdater("lineEvents", lineEventsSchema),
    selectedLineProbes: new ZodParamUpdater(
      "selectedLineProbes",
      selectedProbesSchema,
    ),
  };
}
