import {StringParamUpdater} from "../../util/url.ts";
import {gql} from "../../__generated__";

type recordingsParamUpdaters = {
  env: StringParamUpdater;
};

export function makeRecordingsParamUpdaters(): recordingsParamUpdaters {
  return {
    env: new StringParamUpdater("env"),
  };
}

export const GET_RECORDINGS = gql(/* GraphQL */ `
  query GetRecordings($input: GetRecordingsInput!) {
    getRecordings(input: $input) {
      id
      startTime
      environment
      user {
        id
        firebaseUID
        name
        photoURL
        email
      }
      logs {
        id
        recordingID
        startTime
        processes {
          program
        }
        containsOnlyCpuProfileSamples
      }
      snapshots {
        id
        recordingID
        processSnapshots {
          captureTime
          process {
            program
          }
        }
      }
      executionTraces {
        id
        recordingID
        durationSeconds
        bootTime
        process {
          program
        }
      }
      cpuProfiles {
        id
        recordingID
        processes {
          processID
          binaryID
          processFriendlyName
          captureTime
          duckDBProcessUUID
        }
      }
    }
  }
`);

export const DELETE_RECORDING = gql(/* GraphQL */ `
  mutation DeleteSnapshot($id: ID!) {
    deleteRecording(id: $id)
  }
`);

export const GET_ENVIRONMENTS = gql(/* GraphQL */ `
  query GetEnvironments {
    getEnvironments
  }
`);
