import {AgentReport} from "src/providers/agent-reports-provider.tsx";
import React from "react";
import {Box, List, Stack} from "@mui/material";
import {
  defaultSelectionForEnvironment,
  ProcessHierarchy,
} from "./helpers/processHierarchy.ts";
import {
  ProcessSelection,
  RawProcessSelection,
} from "./helpers/processSelection.ts";
import {ProcessInfo} from "./@types.ts";
import {ProgramSelector} from "./components/ProgramSelector.tsx";
import {ProcessSelectorNoData} from "./components/ProcessSelectorNoData.tsx";
import {ProcessSelectorEnvironment} from "./components/ProcessSelectorEnvironment.tsx";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {ProcessPprofAddresses} from "./helpers/pprofAddresses.ts";

type Props = {
  selection: ProcessSelection | undefined;
  // Callback called whenever the selection changes. A value of undefined means
  // that there's no valid selection.
  onSelectionUpdated: (newSelection: RawProcessSelection | undefined) => void;
  agentReports: AgentReport | undefined;
  // The pprof addresses for the processes.
  pprofInformation?: ProcessPprofAddresses;
};

// ProcessSelector renders the interface for selecting an environment and
// programs/processes out of the options presented in agent reports.
export function ProcessSelector({
  selection,
  onSelectionUpdated,
  agentReports,
  pprofInformation,
}: Props): React.JSX.Element {
  // Deal with the edge cases where the report either hasn't loaded yet, or is
  // empty.
  const procHierarchy =
    agentReports == undefined
      ? undefined
      : new ProcessHierarchy(agentReports.Report.Reports);
  if (
    agentReports == undefined || // report not available yet
    agentReports.NoAgentsReporting || // no agents connected
    agentReports.NoProgramsConfigured || // no programs configured
    procHierarchy!.empty() // no processes reported
  ) {
    return (
      <Stack justifyContent="center" flexWrap="nowrap">
        <ProcessSelectorNoData agentReports={agentReports} />
      </Stack>
    );
  }
  const report = procHierarchy!;

  type envOption = undefined | typeof UNNAMED_ENV | string;

  const selectedEnv: envOption = selection?.environment;
  const programSelection = selection?.programsSelection;

  const processesInSelectedEnv = selectedEnv
    ? report.environments.get(selectedEnv)
    : undefined;

  if (selection != undefined && processesInSelectedEnv == undefined) {
    throw new Error("unexpected undefined processes when there is a selection");
  }

  // We will show the environment selector if environments are in use (i.e. any
  // environments have been reported).
  const showEnvSelector =
    report.multipleEnvironments() || report.singleEnvironment() != undefined;

  function onEnvironmentSelectionChange(newEnv: string | typeof UNNAMED_ENV) {
    // Make a new selection comprised of everything in the newly-selected
    // environment.
    onSelectionUpdated(defaultSelectionForEnvironment(report, newEnv));
  }

  // onProgramToggle toggles the checked state of all processes for one program.
  function onProgramToggle(programName: string, checked: boolean) {
    onSelectionUpdated(
      selection!
        .setProgramSelection(programName, checked ? "all" : "none")
        .toRaw(),
    );
  }

  const onProcessToggle = (process: ProcessInfo, checked: boolean) => {
    onSelectionUpdated(
      selection!.setProcessSelection(process, checked, report).toRaw(),
    );
  };

  return (
    <Box sx={{minHeight: "100px"}}>
      {showEnvSelector && (
        <ProcessSelectorEnvironment
          onEnvironmentSelectionChange={onEnvironmentSelectionChange}
          selectedEnv={selectedEnv}
          report={report}
        />
      )}

      {programSelection && (
        <List dense={true}>
          {Array.from(programSelection).map(
            ([programName, programSelectionStatus]) => (
              <ProgramSelector
                key={programName}
                programName={programName}
                selectionStatus={programSelectionStatus}
                processes={processesInSelectedEnv!.programs.get(programName)!}
                onProgramToggle={(checked) =>
                  onProgramToggle(programName, checked)
                }
                onProcessToggle={onProcessToggle}
                pprofInformation={
                  pprofInformation?.enabled ? pprofInformation : undefined
                }
              />
            ),
          )}
        </List>
      )}
    </Box>
  );
}
