// debouncedFetchOptions returns a debounced version of a function that performs
// a gql query to fetch function suggestions.
import type {ApolloClient} from "@apollo/client";
import {toastError} from "@components/tables/util";
import {gql} from "@graphql/gql";
import type {FunctionLines} from "@graphql/graphql";
import _ from "lodash";

export const debouncedListFileLinesFromBinary: (
  client: ApolloClient<unknown>,
  binaryID: string,
  input: string,
  callback: (results: FunctionLines[]) => void,
) => void = _.debounce(
  (
    client: ApolloClient<unknown>,
    binaryID: string,
    input: string,
    callback: (results: FunctionLines[]) => void,
  ): void => {
    client
      .query({
        query: LIST_FILE_LINES_FROM_BINARY,
        variables: {
          binaryID: binaryID,
          path: input,
        },
      })
      .then(
        (res) => {
          callback(res.data.listFileLinesFromBinary);
        },
        (err) => {
          toastError(err, "failed to get file line suggestions");
          throw err;
        },
      );
  },
  400,
);

const LIST_FILE_LINES_FROM_BINARY = gql(/* GraphQL */ `
  query ListFileLinesFromBinary($binaryID: String!, $path: String!) {
    listFileLinesFromBinary(binaryID: $binaryID, path: $path) {
      Function {
        Package
        Type
        Name
        QualifiedName
      }
      Lines
    }
  }
`);
