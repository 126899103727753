import {getTheme} from "./theme.ts";
import React, {createContext, ReactNode, useState} from "react";
import {ThemeProvider} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {
  getThemeTypeFromStorage,
  setThemeTypeInStorage,
} from "@util/local-storage.ts";

type ThemeSetter = (arg: Theme) => void;
export const ThemeChangeContext = createContext<ThemeSetter>(() => null);
const initialTheme = getTheme(getThemeTypeFromStorage());

export function ThemeContextProvider({children}: {children: ReactNode}) {
  const [currentTheme, setTheme] = useState(initialTheme);

  const changeTheme = (theme: Theme) => {
    setTheme(theme);
    setThemeTypeInStorage(theme.palette.mode);
  };

  return (
    <ThemeChangeContext.Provider value={changeTheme}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeChangeContext.Provider>
  );
}
