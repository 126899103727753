import React from "react";
import FormControl from "@mui/material/FormControl";
import {PprofAddress} from "../@types";
import TextField from "@mui/material/TextField";

export function PprofAddressInput(props: {
  address?: PprofAddress;
  updatePprofAddress: (value: string) => void;
}): React.JSX.Element {
  return (
    <FormControl>
      <TextField
        variant="outlined"
        placeholder="Process pprof address"
        size="small"
        margin="dense"
        value={props.address?.value ?? ""}
        label={
          props.address && "error" in props.address
            ? ""
            : (props.address?.kind ?? "")
        }
        error={props.address && "error" in props.address}
        onChange={(e) => props.updatePprofAddress(e.target.value)}
      />
    </FormControl>
  );
}
