import {useCallback} from "react";
import {toastError} from "@components/tables/util";
import {useApolloClient} from "@apollo/client";
import {GET_PPROF_STACKS_FOR_LOG} from "../gql";
import {Button, Link, Tooltip} from "@mui/material";

export default function PprofDownloadButton({
  logID,
  funcQualifiedName,
}: {
  logID: number;
  funcQualifiedName: string | undefined;
}) {
  const apolloClient = useApolloClient();
  const download = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
      const {data: stacksRes, error: stacksError} = await apolloClient.query({
        query: GET_PPROF_STACKS_FOR_LOG,
        variables: {logID, funcQualifiedName},
      });
      if (stacksError) {
        toastError(stacksError, "Failed to download pprof profile");
        return;
      }
      const stacks = stacksRes.getStacksForLog;
      if (!stacks) {
        return;
      }
      const pprofProfile = stacks.pprofProfile;
      if (!pprofProfile) {
        return;
      }
      // Base64 decode the pprof profile.
      const binaryString = atob(pprofProfile);
      const pprofProfileBytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        pprofProfileBytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([pprofProfileBytes], {
        type: "application/octet-stream",
      });
      const blob_url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      // Write some code to scrub the funcQualifiedName for special characters.
      const scrubbedFuncQualifiedName = funcQualifiedName?.replace(
        /[^a-zA-Z0-9]/g,
        "_",
      );
      a.href = blob_url;
      a.download = scrubbedFuncQualifiedName
        ? `pprof-event-logs-${logID}-${scrubbedFuncQualifiedName}.pb.gz`
        : `pprof-event-logs-${logID}.pb.gz`;
      a.click();
    },
    [apolloClient, logID, funcQualifiedName],
  );
  const tooltip = funcQualifiedName
    ? `Download pprof profile for ${funcQualifiedName}`
    : `Download pprof profile for all functions`;
  return (
    <Tooltip title={tooltip}>
      <Button variant="outlined" color="info">
        <Link onClick={download}>pprof</Link>
      </Button>
    </Tooltip>
  );
}
