import React from "react";

export const Subheader: React.FC = () => {
  return (
    <span>
      Pprof rules are used to determine the address of the pprof HTTP endpoint
      on each process, so that execution traces and CPU profiles can be
      collected.
    </span>
  );
};
