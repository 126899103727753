import {gql} from "../../__generated__";

export const GET_SNAPSHOT = gql(/* GraphQL */ `
  query GetSnapshot($snapshotID: ID!) {
    getSnapshot(id: $snapshotID) {
      id
      recordingID
      captureTime
      environment
      programs
      snapshots {
        processID
        binaryID
        processFriendlyName
        duckDBProcessUUID
      }
      binaries {
        id
        userName
      }
    }
  }
`);

export const GET_STACKS = gql(/* GraphQL */ `
  query GetStacks($snapshotID: Int!, $filters: [StacksFilter!]) {
    getStacks(snapshotID: $snapshotID, filters: $filters) {
      GoroutineGroups {
        Goroutines {
          ProcessID
          Goroutines {
            ID {
              ProcessID
              ID
            }
            Status
            WaitForNanos
            WaitReason
          }
        }
        Frames {
          BinaryID
          FuncName {
            Package
            Type
            Name
            QualifiedName
          }
          File
          Line
          Inlined
          PC
          Data {
            ColumnValues
            Links {
              LinkID
              Side
              NumLinks
              SampleGoroutineID {
                ProcessID
                ID
              }
            }
          }
        }
      }
      TableSchemas {
        ...FullTableSchema
      }
    }
  }
`);
