// debouncedFetchOptions returns a debounced version of a function that performs
// a gql query to fetch function suggestions.
import type {ApolloClient} from "@apollo/client";
import {toastError} from "@components/tables/util";
import {gql} from "@graphql/gql";
import _ from "lodash";

export const debouncedListFilesFromBinary: (
  client: ApolloClient<unknown>,
  binaryID: string,
  input: string,
  callback: (results: string[]) => void,
) => void = _.debounce(
  (
    client: ApolloClient<unknown>,
    binaryID: string,
    input: string,
    callback: (results: string[]) => void,
  ): void => {
    client
      .query({
        query: LIST_FILES_FROM_BINARY,
        variables: {
          binaryID: binaryID,
          filter: input,
          limit: 50,
        },
      })
      .then(
        (res) => {
          callback(res.data.listFilesFromBinary);
        },
        (err) => {
          toastError(err, "failed to get file suggestions");
          throw err;
        },
      );
  },
  400,
);

const LIST_FILES_FROM_BINARY = gql(/* GraphQL */ `
  query ListFilesFromBinary($binaryID: String!, $filter: String, $limit: Int) {
    listFilesFromBinary(binaryID: $binaryID, filter: $filter, limit: $limit)
  }
`);
