import type React from "react";
import {useEffect, useState} from "react";
import {useApolloClient} from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {SxProps, Theme} from "@mui/material/styles";
import {debouncedListFilesFromBinary} from "@util/debouncedListFilesFromBinary";

type FileAutocompleteProps = {
  sx: SxProps<Theme>;

  binaryID: string;
  // value is the selected file path.
  value: string | null;
  // inputValue is the text in the input box. In order for the parent to be able
  // to clear the Autocomplete, it seems that it needs to control both value and
  // inputValue.
  inputValue: string;
  onValueChange: (val: string | null) => void;
  onInputChange: (val: string) => void;
};

export default function FileAutocomplete(
  props: FileAutocompleteProps,
): React.JSX.Element {
  const [pending, setPending] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);
  const client = useApolloClient();

  useEffect(() => {
    let active = true;

    if (props.inputValue === "") {
      setOptions([]);
      return undefined;
    }
    setPending(true);
    debouncedListFilesFromBinary(
      client,
      props.binaryID,
      props.inputValue,
      (results: string[]): void => {
        // If this request was cancelled, ignore the result.
        if (!active) {
          return;
        }
        if (results) {
          setOptions(results);
          setPending(false);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [client, props.binaryID, props.inputValue]);

  return (
    <Autocomplete
      sx={props.sx}
      disablePortal
      // Disable the built-in filtering because we do our own filtering by
      // requesting new suggestions. As instructed by
      // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      filterOptions={(o) => o}
      clearOnBlur={false}
      noOptionsText={
        props.inputValue
          ? pending
            ? "Searching..."
            : "No files found"
          : "Type to search"
      }
      options={!pending ? options : []}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          placeholder={"Specify source file"}
        />
      )}
      inputValue={props.inputValue}
      getOptionLabel={(option: string) => option}
      getOptionKey={(option: string) => option}
      value={props.value}
      isOptionEqualToValue={(option, value) => option == value}
      onInputChange={(_event, newInputValue, _reason) => {
        props.onInputChange(newInputValue);
      }}
      onChange={(_event, newValue: string | null, _reason) => {
        props.onValueChange(newValue);
      }}
      // Make the home/end keys move the cursor in the text box as opposed to
      // changing the selection in the dropdown.
      handleHomeEndKeys={false}
    />
  );
}
