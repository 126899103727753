import React, {createContext, PropsWithChildren} from "react";
import {SnapshotSpec} from "../__generated__/graphql.ts";
import {gql} from "../__generated__";
import {useSuspenseQuery} from "@apollo/client";

const GET_SPEC = gql(/* GraphQL */ `
  query GetSpec {
    getSpec {
      id
      ...FullSnapshotSpec
      missingTypeQualifiedNames
    }
  }
`);

gql(/* GraphQL */ `
  fragment FullSnapshotSpec on SnapshotSpec {
    id
    modules {
      name
      pkgPath
      functionSpecs {
        funcName {
          QualifiedName
          Package
          Type
          Name
        }
        snapshotSpec {
          tableName
          collectExprs {
            expr
            opt {
              collectAllCollection
            }
            column {
              name
              type
              hidden
            }
          }
          extraColumns {
            name
            expr
            type
            hidden
          }
        }
        functionStartEvent {
          message
          tableName
          collectExprs {
            expr
            opt {
              collectAllCollection
            }
            column {
              name
              type
              hidden
            }
          }
          extraColumns {
            name
            expr
            type
            hidden
          }
        }
      }
      typeSpecs {
        typeQualifiedName
        collectExprs {
          expr
          column {
            name
            type
            hidden
          }
          opt {
            collectAllCollection
          }
        }
        collectAll
      }
      goContextSpecs {
        keyType
        valueType {
          typeQualifiedName
          collectAll
          collectExprs {
            expr
            column {
              name
              type
              hidden
            }
            opt {
              collectAllCollection
            }
          }
        }
      }
    }
    tables {
      id
      name
      query
      columns {
        name
        hidden
        expr
        type
      }
    }
    programs {
      programName
      processFriendlyNameExpr
      processFriendlyNameFmt
      processFriendlyNameFunctionQualifiedName
    }
    links {
      ...FullLinkSpec
    }
  }
`);

gql(/* GraphQL */ `
  fragment FullLinkSpec on LinkSpec {
    id
    note
    srcTable {
      ... on FunctionReference {
        FuncQualifiedName
      }
      ... on EventTableReference {
        FuncQualifiedName
        LineNumber
      }
      ... on DerivedTableReference {
        TableID
      }
      ... on BuiltinTableReference {
        TableName
      }
    }
    srcCols
    destTable {
      ... on FunctionReference {
        FuncQualifiedName
      }
      ... on EventTableReference {
        FuncQualifiedName
        LineNumber
      }
      ... on DerivedTableReference {
        TableID
      }
      ... on BuiltinTableReference {
        TableName
      }
    }
    destCols
    modules
  }
`);

export const SpecContext = createContext<SnapshotSpec>(undefined as never);
export const SpecProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const {data, error} = useSuspenseQuery(GET_SPEC);

  if (error) {
    console.error("Error in SpecProvider:", error);
    return <div>Error loading spec</div>;
  }

  return (
    <SpecContext.Provider value={data.getSpec as SnapshotSpec}>
      {children}
    </SpecContext.Provider>
  );
};
