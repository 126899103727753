import {useSuspenseQuery} from "@apollo/client";
import EventFrequency from "@components/EventFrequency.tsx";
import EventsFlamegraph from "@components/EventsFlamegraph";
import HistogramPicker from "@components/HistogramPicker/HistogramPicker.tsx";
import {
  LogDownloadButton,
  RecordingDatabaseDownloadButton,
} from "@components/download-buttons";
import GoroutineLink from "@components/goroutine-link.tsx";
import {Tables} from "@components/tables/tables.tsx";
import {GoroutineId, Tables_Kind} from "@graphql/graphql";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ProcessResolverProvider,
  useProcessResolver,
} from "@providers/processResolverProvider";
import {RecordingProvider, useRecording} from "@providers/recordingProvider";
import {ProcessResolver} from "@util/process-resolver.ts";
import {GET_RECORDING_DATABASE_URL} from "@util/queries";
import {
  formatDurationNanos,
  NANOS_PER_MILLI_BIGINT,
  ProcessInfo,
} from "@util/util.ts";
import React, {useContext} from "react";
import {useParams} from "react-router-dom";
import {renderPossiblyJSONValue} from "src/components/cell.tsx";
import {
  AppConfigContext,
  getAppXUrl,
} from "../../providers/app-config-provider";
import PprofDownloadButton from "./components/PprofDownloadButton";
import {GET_LOG, GET_LOG_EVENTS} from "./gql";

export default function EventLogViewer(): React.JSX.Element {
  const pathParams = useParams();
  const logID = parseInt(pathParams.logID!);
  const [tab, setTab] = React.useState("log");
  const [selectedFunc, setSelectedFunc] = React.useState<string | undefined>(
    undefined,
  );

  const {data: logRes} = useSuspenseQuery(GET_LOG, {
    variables: {id: logID},
  });
  const {
    data: {getRecordingDatabaseURL: recordingURL},
  } = useSuspenseQuery(GET_RECORDING_DATABASE_URL, {
    variables: {id: logRes.getLog.recordingID},
  });

  const log = logRes.getLog;

  const processResolver = new ProcessResolver(
    log.processes.map(
      (s): ProcessInfo => ({
        ProcessID: s.processID,
        BinaryID: s.binaryID,
        FriendlyName: s.processFriendlyName,
        CaptureTimeNanos:
          BigInt(Date.parse(s.captureTime)) * NANOS_PER_MILLI_BIGINT,
        DuckDBProcessUUID: s.duckDBProcessUUID,
      }),
    ),
    [] /* binaries */,
  );

  return (
    <div>
      <TabContext value={tab}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{width: "100%"}}
        >
          <TabList variant="standard" sx={{width: "100%"}}>
            <Tab value={"log"} label="Log view" onClick={() => setTab("log")} />
            <Tab
              value={"tables"}
              label="Data tables"
              onClick={() => setTab("tables")}
            />
          </TabList>
          <span />
          <Stack direction={"row"} spacing={1}>
            <PprofDownloadButton
              logID={logID}
              funcQualifiedName={selectedFunc}
            />
            <LogDownloadButton logID={logID} />
            <RecordingDatabaseDownloadButton
              duckdbRecordingURL={recordingURL}
              recordingID={log.recordingID}
            />
          </Stack>
        </Stack>

        <RecordingProvider value={{recordingID: log.recordingID}}>
          <ProcessResolverProvider value={processResolver}>
            <TabPanel value={"log"}>
              <Box sx={{width: "100%", height: "400px"}}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{width: "100%", height: "400px"}}
                >
                  <Box sx={{width: "50%", height: "100%"}}>
                    <EventFrequency logID={logID} poll={false} />
                  </Box>
                  <Box sx={{width: "50%", height: "100%"}}>
                    <HistogramPicker log={log} poll={false} />
                  </Box>
                </Stack>
              </Box>

              <EventsFlamegraph
                log={log}
                poll={false}
                selectedFunc={selectedFunc}
                setSelectedFunc={setSelectedFunc}
                startExpanded={false}
              />
              <EventsTable logID={logID} />
            </TabPanel>

            <TabPanel value={"tables"}>
              <Tables logID={logID} tablesKind={Tables_Kind.Events} />
            </TabPanel>
          </ProcessResolverProvider>
        </RecordingProvider>
      </TabContext>
    </div>
  );
}

// EventsTable renders a table with all the events in a log.
function EventsTable({logID}: {logID: number}): React.JSX.Element {
  const appConfig = useContext(AppConfigContext);
  const processResolver = useProcessResolver();
  const recordingContext = useRecording()!;
  const {data: eventsRes, error} = useSuspenseQuery(GET_LOG_EVENTS, {
    errorPolicy: "all", // returns errors instead of throwing
    variables: {logID},
  });
  if (error) {
    return <>Error: {error.message}</>;
  }
  const events = eventsRes!.getLogEvents;

  const goroutineURL = (gid: GoroutineId): string => {
    const resolvedG = processResolver.resolveGoroutineID(gid);
    const recordingID = recordingContext.recordingID;
    return `${getAppXUrl(appConfig)}/recordings/${recordingID}/goroutines/${resolvedG.ID}.${resolvedG.duckDBProcessUUID}/flamegraph`;
  };

  return (
    <Table
      style={{
        marginTop: "10px",
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell style={{width: "150px"}}>GoroutineID</TableCell>
          <TableCell style={{width: "250px"}}>Timestamp</TableCell>
          <TableCell style={{width: "300px"}}>Duration</TableCell>
          <TableCell style={{width: "100%"}}>Message</TableCell>
          <TableCell style={{width: "100%"}}>Data</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {events.map((event, i) => (
          <TableRow key={`${i}`}>
            <TableCell>
              <GoroutineLink
                processID={event.goroutineID.ProcessID}
                goroutineID={event.goroutineID.ID}
                clickURL={goroutineURL(event.goroutineID)}
              />
            </TableCell>
            <TableCell>{event.timestamp}</TableCell>
            <TableCell>
              {event.funcDurationNanos
                ? formatDurationNanos(event.funcDurationNanos)
                : ""}
            </TableCell>
            <TableCell>{event.message}</TableCell>
            <TableCell>
              {renderPossiblyJSONValue(
                event.data,
                undefined /* onExpand */,
                0 /* defaultExpandedLevels */,
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
