export const ROUTER_PATHS = {
  homePage: "/",
  recordings: "/recordings",
  snapshot: "/snapshots/:snapshotID",
  cpuprofile: "/cpuprofile/:profileID",
  login: "/login",
  downloadDatabaseButton: "/download-database",
  processFriendlyNameComposer: "/process-friendly-name-composer",
  agents: "/agents",
  binaries: "/binaries",
  schedule: "/schedule",
  snapshotSpecEditor: "/spec",
  captureRecording: "/capture-recording",
  logout: "/logout",
  log: "/logs/:logID",
  graphiql: "/graphiql",
  generateTraceDB: "/generate-trace-db",
};

export function pathComponent(path: string): string {
  // Return only the first element of the path, up to the slash.
  if (!path.startsWith("/")) {
    throw new Error(`Invalid path: ${path}`);
  }
  return path.split("/", 2 /* limit */)[1];
}
