import {SnapshotInfoMinimal} from "@pages/RecordingsList/@types";
import {
  FunctionName,
  Goroutine,
  GoroutineId,
} from "src/__generated__/graphql.ts";

// The user's timezone.
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const dateFormat = new Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
  timeZone: tz,
});

// datetimeStringToDisplayString takes a string representation of a datetime in
// some format taht Date.parse() understands (e.g. in the RFC3339Nano format
// that our GraphQL queries use) and returns the string representation of that
// timestamp in a nicer format suitable for display to the user.
export function datetimeStringToDisplayString(date: string): string {
  const timestamp = Date.parse(date);
  return dateFormat.format(timestamp);
}

// formatDurationNanos formats a duration in nanoseconds as a human-readable.
//
// forceMicros specifies that, if the duration is between 1ms and 1s, it should
// be formatted as milliseconds.microseconds, instead of simply milliseconds.
export function formatDurationNanos(
  nanos: number,
  forceMicros: boolean = false,
): string {
  const NANOS_PER_SECOND = NANOS_PER_MILLI * 1000;
  const NANOS_PER_MINUTE = NANOS_PER_SECOND * 60;
  const NANOS_PER_HOUR = NANOS_PER_MINUTE * 60;

  if (nanos < NANOS_PER_SECOND) {
    return formatSubSecondDuration(nanos, forceMicros);
  }

  const hours = Math.floor(nanos / NANOS_PER_HOUR);
  const hourStr = hours ? hours + "h " : "";
  const minutes = Math.floor((nanos % NANOS_PER_HOUR) / NANOS_PER_MINUTE);
  let minuteStr = "";
  if (hourStr) {
    minuteStr = minutes.toFixed(0).padStart(2, "0") + "m ";
  } else if (minutes) {
    minuteStr = minutes + "m ";
  }
  const secondPadNum = hourStr || minuteStr ? 2 : 1;
  const secondStr = Math.floor((nanos % NANOS_PER_MINUTE) / NANOS_PER_SECOND)
    .toString()
    .padStart(secondPadNum, "0");
  const msStr =
    "." +
    Math.floor((nanos % NANOS_PER_SECOND) / NANOS_PER_MILLI)
      .toString()
      .padStart(3, "0") +
    "s";
  return hourStr + minuteStr + secondStr + msStr;
}

// formatSubSecondDuration formats durations under 1s as a human-readable string.
// If forceMicros is true, durations between 1ms and 1s are formatted as `ms.µs`.
function formatSubSecondDuration(nanos: number, forceMicros: boolean): string {
  if (nanos < 1000) {
    return nanos + "ns";
  }
  if (nanos < NANOS_PER_MILLI) {
    const micros = Math.floor(nanos / 1000);
    return micros + "µs";
  }
  const millis = nanos / NANOS_PER_MILLI;
  return millis.toFixed(forceMicros ? 3 : 0) + "ms";
}

export type ProcessInfo = {
  ProcessID: number;
  BinaryID: string;
  FriendlyName: string;
  CaptureTimeNanos: bigint;
  DuckDBProcessUUID: string;
};

export type resolvedGoroutineGroup = {
  process: ProcessInfo;
  goroutines: Array<Goroutine>;
};

// snapshotName assembles the "name of a snapshot", as it should be displayed to
// the user.
export function snapshotName(snap: SnapshotInfoMinimal): string {
  const formattedDate = dateFormat.format(snap.captureTime);
  const prefix = snap.environment ? `${snap.environment}: ` : "";
  return `${prefix}${formattedDate} (${snap.programs.join(", ")})`;
}

export function exhaustiveCheck(_: never): never {
  throw new Error(`unreachable`);
}

export function parseGoroutineID(s: string): GoroutineId {
  const [processIDStr, goroutineIDStr] = s.split(":");
  const procID = parseInt(processIDStr);
  if (isNaN(procID)) {
    throw new Error(`invalid process snapshot ID: ${processIDStr}`);
  }
  const goroutineID = parseInt(goroutineIDStr);
  if (isNaN(goroutineID)) {
    throw new Error(`invalid goroutine ID: ${goroutineIDStr}`);
  }
  return {
    ProcessID: procID,
    ID: goroutineID,
  };
}

export function goroutineIDToString(g: GoroutineId): string {
  return `${g.ProcessID}:${g.ID}`;
}

export const NANOS_PER_SECOND: number = 1000 * 1000 * 1000;
export const NANOS_PER_MILLI: number = 1000 * 1000;
export const NANOS_PER_MILLI_BIGINT: bigint = 1000n * 1000n;

// funcOrMethodNameWithShortPkg returns <last component of pkg>[.<type>].<func name>.
export function funcOrMethodNameWithShortPkg(fn: FunctionName): string {
  // Find the last slash in the package name and return the suffix after it.
  const lastSlash = fn.Package.lastIndexOf("/");
  const packageSuffix =
    lastSlash === -1 ? fn.Package : fn.Package.slice(lastSlash + 1);
  return fn.Type !== ""
    ? `${packageSuffix}.${fn.Type}.${fn.Name}`
    : `${packageSuffix}.${fn.Name}`;
}
