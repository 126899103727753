import {createTheme, PaletteOptions} from "@mui/material";
import {MuiSwitch} from "./mui-components/MuiSwitch.ts";
import {MuiMenuItem} from "./mui-components/MuiMenuItem.ts";
import {MuiTable} from "./mui-components/MuiTable.ts";
import {MuiSelect} from "./mui-components/MuiSelect.ts";
import {MuiInputBase} from "./mui-components/MuiInputBase.ts";
import {MuiButton} from "./mui-components/MuiButton.ts";
import {MuiToolbar} from "./mui-components/MuiToolbar.ts";
import {MuiDrawer} from "./mui-components/MuiDrawer.ts";
import {MuiLink} from "./mui-components/MuiLink.ts";
import {MuiCardContent} from "./mui-components/MuiCardContent.ts";
import {MuiCard} from "./mui-components/MuiCard.ts";
import {MuiDivider} from "./mui-components/MuiDivider.ts";
import {MuiPaper} from "./mui-components/MuiPaper.ts";
import {MuiAutocomplete} from "./mui-components/MuiAutocomplete.tsx";
import {MuiTextField} from "./mui-components/MuiTextField.ts";
import {MuiIconButton} from "./mui-components/MuiIconButton.ts";
import {MuiCheckbox} from "./mui-components/MuiCheckbox.tsx";
import {MuiListItem} from "./mui-components/MuiListItem.ts";
import {MuiList} from "./mui-components/MuiList.ts";
import {MuiBreadcrumbs} from "./mui-components/MuiBreadcrumbs.ts";
import {MuiTab} from "./mui-components/MuiTab.ts";
import {MuiFormControlLabel} from "./mui-components/MuiFormControlLabel.ts";
import {MuiTableContainer} from "./mui-components/MuiTableContainer.ts";
import {MuiCssBaseline} from "./mui-components/MuiCssBaseline.ts";
import {MuiCardHeader} from "./mui-components/MuiCardHeader.ts";
import {MuiTypography} from "./mui-components/MuiTypography.ts";
import {MuiAccordion} from "./mui-components/MuiAccordion.tsx";
import {MuiChip} from "./mui-components/MuiChip.ts";
import {MuiTooltip} from "./mui-components/MuiTooltip.ts";
import {Palette, PaletteMode} from "@mui/material/styles/createPalette";
import {TypographyOptions} from "@mui/material/styles/createTypography";
import {Theme} from "@mui/material/styles";

const components = {
  ...MuiPaper(),
  ...MuiDivider(),
  ...MuiCard(),
  ...MuiCardContent(),
  ...MuiLink(),
  ...MuiDrawer(),
  ...MuiToolbar(),
  ...MuiButton(),
  ...MuiInputBase(),
  ...MuiSelect(),
  ...MuiTable(),
  ...MuiMenuItem(),
  ...MuiSwitch(),
  ...MuiAutocomplete(),
  ...MuiTextField(),
  ...MuiIconButton(),
  ...MuiCheckbox(),
  ...MuiList(),
  ...MuiListItem(),
  ...MuiBreadcrumbs(),
  ...MuiTab(),
  ...MuiFormControlLabel(),
  ...MuiTableContainer(),
  ...MuiCssBaseline(),
  ...MuiCardHeader(),
  ...MuiTypography(),
  ...MuiAccordion(),
  ...MuiChip(),
  ...MuiTooltip(),
};

const typography: (palette: Palette) => TypographyOptions = ({
  text,
  error,
}) => ({
  fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
  button: {
    textTransform: "none",
  },
  h1: {
    fontSize: 32,
    fontWeight: 700,
  },
  h2: {
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: 400,
  },
  body1: {
    fontSize: 20,
    fontWeight: "400",
  },
  body2: {
    fontSize: 16,
    lineHeight: "24px",
  },
  body3: {
    fontSize: 14,
    lineHeight: "20px",
  },
  body4: {
    fontSize: 13,
    fontWeight: 500,
  },
  caption: {
    color: text.secondary,
  },
  warning: {
    color: "orange",
    fontSize: 14,
  },
  error: {
    color: error.main,
    fontSize: 14,
  },
  muted: {
    fontSize: "small",
    color: "#888",
  },
  mutedNormalSize: {
    fontSize: "normal",
    color: "#888",
  },
});

const NewPalette = {
  accent: "#8453FF",
  neutral: {
    0: "#FFFFFF",
    50: "#F7F7F7",
    75: "#E8E8E8",
    100: "#DADADB",
    200: "#C2C1C3",
    300: "#9D9C9F",
    400: "#848386",
    500: "#666468",
    600: "#47454A",
    650: "#2B2830",
    700: "#1F1D24",
    800: "#18161D",
    900: "#131118",
    1000: "#09070E",
  },
};

const darkPalette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: NewPalette.accent,
    light: "#D4C2FF",
  },
  secondary: {
    main: NewPalette.neutral["300"],
    dark: NewPalette.neutral["700"],
  },
  info: {
    main: NewPalette.neutral["600"],
    dark: NewPalette.neutral["500"],
  },
  text: {
    primary: NewPalette.neutral["0"],
    secondary: NewPalette.neutral["200"],
    logo: "#B6E4FA",
  },
  background: {
    default: NewPalette.neutral["900"],
    paper: NewPalette.neutral["800"],
    component: NewPalette.neutral["700"],
    drawer: NewPalette.neutral["1000"],
    sidebarFooter: NewPalette.neutral["800"],
    gradient: "linear-gradient(180deg, #28194D 0%, #1A1131 100%)",
  },
};

const lightPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: NewPalette.accent,
    light: "#AE8BFF",
  },
  secondary: {
    main: NewPalette.neutral["300"],
    dark: NewPalette.neutral["75"],
  },
  info: {
    main: NewPalette.neutral["600"],
    dark: NewPalette.neutral["500"],
  },
  text: {
    primary: NewPalette.neutral["900"],
    secondary: NewPalette.neutral["600"],
    logo: "#1A1B41",
  },
  background: {
    default: NewPalette.neutral["50"],
    paper: NewPalette.neutral["0"],
    component: NewPalette.neutral["75"],
    drawer: NewPalette.neutral["75"],
    sidebarFooter: NewPalette.neutral["100"],
    gradient:
      "linear-gradient(180deg, " +
      NewPalette.neutral["75"] +
      " 0%, " +
      NewPalette.neutral["50"] +
      " 100%)",
  },
};

export const darkTheme = createTheme({
  palette: darkPalette,
  typography,
  components,
});

export const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  components,
});

export function getTheme(palleteMode: PaletteMode): Theme {
  if (palleteMode == "dark") {
    return darkTheme;
  }
  return lightTheme;
}
