import React from "react";
import {Box, Button, Checkbox, Stack, Typography} from "@mui/material";
import {
  ColumnType,
  FunctionName,
  FunctionStartEventSpec,
} from "@graphql/graphql";
import {FunctionTableEditor} from "@components/FunctionTableEditor";
import {FunctionSpecEditorInterface} from "@util/function-spec-editing";

export default function LineProbeItem(props: {
  file: string;
  funcName: FunctionName;
  lineNumber: number;
  eventSpec: FunctionStartEventSpec;

  selected: boolean;
  onSelectedChange: (
    funcName: FunctionName,
    lineNumber: number,
    selected: boolean,
  ) => void;
  onExprAdded: (
    funcName: FunctionName,
    lineNumber: number,
    expr: string,
  ) => void;
  onExprRemoved: (
    funcName: FunctionName,
    lineNumber: number,
    expr: string,
  ) => void;

  binaryID: string | undefined;
}): React.JSX.Element {
  const [showVars, setShowVars] = React.useState(false);

  // A FunctionSpecEditorInterface that implements only the necessary methods
  // for editing the line events: i.e. adding and removing expressions.
  const eventSpecEditor: FunctionSpecEditorInterface = {
    onAddFunctionStartEvent(): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onAddSnapshotSpec(): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onColumnHiddenUpdated(expr: string, newHidden: boolean): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onColumnNameUpdated(expr: string, newName: string): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onEventMessageEdited(newMessage: string): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onEventSpecDelete(): Promise<boolean> {
      return Promise.resolve(false);
    },
    onExpressionCollectChange(
      expr: string,
      checked: boolean,
    ): Promise<boolean> {
      if (checked) {
        props.onExprAdded(props.funcName, props.lineNumber, expr);
      } else {
        props.onExprRemoved(props.funcName, props.lineNumber, expr);
      }
      return Promise.resolve(true);
    },
    onExpressionDeleted(expr: string): Promise<boolean> {
      props.onExprRemoved(props.funcName, props.lineNumber, expr);
      return Promise.resolve(true);
    },
    onExtraColumnAdded(
      colName: string,
      colExpr: string,
      colType: ColumnType,
      hidden: boolean,
    ): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onExtraColumnDeleted(columnName: string): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onExtraColumnEdited(
      origName: string,
      newName: string,
      expr: string,
      colType: ColumnType,
      hidden: boolean,
    ): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onSnapshotSpecDelete(): Promise<boolean> {
      throw new Error("Method not supported.");
    },
    onTableNameUpdated(newTableName: string): Promise<boolean> {
      throw new Error("Method not supported.");
    },
  };

  return (
    <Box>
      <Stack direction="row" alignItems={"center"}>
        <Checkbox
          checked={props.selected}
          onChange={(event) =>
            props.onSelectedChange(
              props.funcName,
              props.lineNumber,
              event.target.checked,
            )
          }
        />
        <Stack direction={"row"} gap={2}>
          <Stack direction={"row"} gap={0.5}>
            <Typography variant={"mutedNormalSize"}>Function:</Typography>
            {props.funcName.QualifiedName}
          </Stack>
          <Stack direction={"row"} gap={0.5}>
            <Typography variant={"mutedNormalSize"}>File/line:</Typography>
            {props.file}:{props.lineNumber}
          </Stack>
        </Stack>
      </Stack>

      {showVars && props.binaryID != undefined ? (
        <>
          <Button onClick={() => setShowVars(false)}>
            Hide captured variables
          </Button>
          <FunctionTableEditor
            labels={{
              variablesLabel: "Variables included in the event",
              variablesTooltip: `The set of variables to collect and expressions to evaluate
                    whenever this line of code is hit.`,
              capturedExprTooltip: `The name of the column representing this captured
                    expression in the event's table.`,
              tableNameTooltip: `The table name under which this event table is stored in
                    the events database. This is the table name to use in SQL queries.`,
              extraColsTooltip: `Extra columns for the events table, in addition to 
                    the columns defined implicitly by the captured variables above. These 
                    extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top 
                    of the implicit columns (i.e. the expressions can reference these implicit columns; 
                    the names of implicit columns containing dots should be quoted like "myVar.myField").`,
            }}
            binaryID={props.binaryID}
            specEditor={eventSpecEditor}
            tableSpec={props.eventSpec}
            funcQualifiedName={props.funcName.QualifiedName}
            paramsOnly={false}
          />
        </>
      ) : (
        <Button onClick={() => setShowVars(true)}>
          Show captured variables
        </Button>
      )}
    </Box>
  );
}
