import {Button, Link, Tooltip} from "@mui/material";

export type RecordingDatabaseDownloadButton = {
  duckdbRecordingURL: string;
  recordingID: number;
};

export function RecordingDatabaseDownloadButton({
  duckdbRecordingURL,
  recordingID,
}: RecordingDatabaseDownloadButton) {
  const url = duckdbRecordingURL;
  const tooltip = "Download a DuckDB database with this recording's data";
  const fallbackFilename = `recording-${recordingID}.duckdb`;
  return (
    <Tooltip title={tooltip}>
      <Button variant="outlined" color="info">
        <Link href={url} download={fallbackFilename}>
          <img src={"/duckdb.png"} alt="DuckDB" />
        </Link>
      </Button>
    </Tooltip>
  );
}
