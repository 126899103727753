import {
  AppConfigContext,
  getApiUrlForPath,
} from "@providers/app-config-provider";
import {useContext} from "react";
import {DownloadDatabaseButton} from "./base/DownloadDatabaseButton";

export type SnapshotDownloadButtonProps = {
  snapshotID: number;
};

export function SnapshotDownloadButton({
  snapshotID,
}: SnapshotDownloadButtonProps) {
  const appConfig = useContext(AppConfigContext);
  const url = getApiUrlForPath(appConfig.APIBaseURL, `/db/${snapshotID}`);
  const tooltip = "Download a SQLite database with this snapshot's data";
  const fallbackFilename = `snapshot-${snapshotID}.sqlite`;
  return (
    <DownloadDatabaseButton
      url={url}
      tooltip={tooltip}
      fallbackFilename={fallbackFilename}
    />
  );
}
