import {
  AppConfigContext,
  getApiUrlForPath,
} from "@providers/app-config-provider.tsx";
import {useContext} from "react";
import {DownloadDatabaseButton} from "./base/DownloadDatabaseButton.tsx";

export type LogDownloadButtonProps = {
  logID: number;
};

export function LogDownloadButton({logID}: LogDownloadButtonProps) {
  const appConfig = useContext(AppConfigContext);
  const url = getApiUrlForPath(appConfig.APIBaseURL, `/log/${logID}`);
  const tooltip = "Download a SQLite database with this log's data";
  const fallbackFilename = `log-${logID}.sqlite`;
  return (
    <DownloadDatabaseButton
      url={url}
      tooltip={tooltip}
      fallbackFilename={fallbackFilename}
    />
  );
}
