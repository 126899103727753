import {z} from "zod";
import {ProgramSelection, ProgramSelectionType} from "@graphql/graphql.ts";
import {ProcessInfo, ProgramSelectionStatus} from "../@types.ts";
import {ProcessHierarchy} from "./processHierarchy.ts";
import {exhaustiveCheck} from "@util/util.ts";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {useContext} from "react";
import {
  AgentReport,
  AgentReportsContext,
} from "@providers/agent-reports-provider";

export class ProcessSelection {
  // The selected environment.
  readonly environment: string | typeof UNNAMED_ENV;
  // programsSelection maps from program name to the selection status of that
  // program. The programs correspond to the selected environment.
  readonly programsSelection: Map<string, ProgramSelectionStatus>;

  constructor(
    environment: string | typeof UNNAMED_ENV,
    programsSelection: Map<string, ProgramSelectionStatus> = new Map<
      string,
      ProgramSelectionStatus
    >(),
  ) {
    this.environment = environment;
    this.programsSelection = programsSelection;
  }

  // empty returns true if no programs or processes are selected.
  empty = (): boolean => {
    for (const [_k, v] of this.programsSelection) {
      if (v.type == "all") {
        return false;
      }
      if (v.type == "some" && v.selectedProcs.length != 0) {
        return false;
      }
    }
    return true;
  };

  setProgramSelection(
    programName: string,
    state: "all" | "none",
  ): ProcessSelection {
    this.programsSelection.set(programName, {type: state});
    return new ProcessSelection(this.environment, this.programsSelection);
  }

  setProcessSelection(
    process: ProcessInfo,
    selected: boolean,
    report: ProcessHierarchy,
  ): ProcessSelection {
    const env = process.environment;
    const program = process.programName;

    const programSelection = this.programsSelection.get(program);
    if (programSelection === undefined) {
      console.warn("bug: no program selection status for process", process);
      return this;
    }

    const processes = report.processesForEnvAndProgram(env, program);
    let selectedProcs: ProcessInfo[];
    switch (programSelection.type) {
      case "all":
        selectedProcs = [...processes];
        break;
      case "none":
        selectedProcs = [];
        break;
      case "some":
        selectedProcs = programSelection.selectedProcs;
        break;
      default:
        exhaustiveCheck(programSelection);
    }
    if (selected) {
      if (selectedProcs.some((p) => p.processToken == process.processToken)) {
        console.warn(
          "bug: attempting to select a process that is already selected",
        );
        return this;
      }
      selectedProcs.push(process);
    } else {
      selectedProcs = selectedProcs.filter(
        (p) => p.processToken != process.processToken,
      );
    }

    if (selectedProcs.length == processes.length) {
      this.programsSelection.set(program, {type: "all"});
    } else if (selectedProcs.length > 0) {
      this.programsSelection.set(program, {type: "some", selectedProcs});
    } else {
      this.programsSelection.set(program, {type: "none"});
    }
    return new ProcessSelection(this.environment, this.programsSelection);
  }

  // toJSON is called by JSON.stringify(). We create an object that
  // JSON.stringify() can deal with: no maps or symbols. The schema of the
  // resulting JSON is codified by ProcessSelectionJSONSchema.
  toJSON() {
    return {
      environment: this.environment == UNNAMED_ENV ? null : this.environment,
      programsSelection: Array.from(this.programsSelection.entries()),
    };
  }

  toRaw(): RawProcessSelection {
    // To produce a RawProcessSelection, we marshall to JSON, and then parse the
    // result according to the Zod schema.
    return ProcessSelectionJSONSchema.parse(JSON.parse(JSON.stringify(this)));
  }

  // hydrateFromRaw creates a ProcessSelection from a RawProcessSelection and a
  // ProcessHierarchy. If the selection is undefined, or the report is
  // undefined, or the selection does not match the report at all and the report
  // does not have a default selection, returns undefined.
  static hydrateFromRaw(
    selection: RawProcessSelection | undefined,
    report: ProcessHierarchy | undefined,
  ): ProcessSelection | undefined {
    const compatibleSelection = syncProcessSelectionWithReport(
      selection,
      report,
    );
    if (compatibleSelection == undefined) {
      return undefined;
    }
    // Convince the compiler that report is defined from now on.
    if (report == undefined) {
      throw new Error(
        "undefined report was supposed to result in undefined selection",
      );
    }

    const env: string | typeof UNNAMED_ENV =
      compatibleSelection.environment == null
        ? UNNAMED_ENV
        : compatibleSelection.environment;

    const procSelection = new Map<string, ProgramSelectionStatus>();
    for (const [program, selection] of compatibleSelection.programsSelection) {
      switch (selection.type) {
        case "all":
          procSelection.set(program, {type: "all"});
          break;
        case "none":
          procSelection.set(program, {type: "none"});
          break;
        case "some":
          procSelection.set(program, {
            type: "some",
            selectedProcs: selection.selectedProcs.map(
              (token) =>
                // We know that the report has the environment and the process.
                report.environments.get(env)!.getProcess(program, token)!,
            ),
          });
          break;
        default:
          exhaustiveCheck(selection);
      }
    }

    return new ProcessSelection(env, procSelection);
  }
}

// ProcessSelectionJSONSchema describes the object produced by
// ProcessSelection.toJSON().
export const ProcessSelectionJSONSchema = z.object({
  environment: z.string().nullable(),
  programsSelection: z.array(
    z.tuple([
      z.string(),
      z.union([
        z.object({type: z.literal("all")}),
        z.object({type: z.literal("none")}),
        z.object({
          type: z.literal("some"),
          selectedProcs: z.array(z.string()),
        }),
      ]),
    ]),
  ),
});

// RawProcessSelection is the type corresponding to the
// ProcessSelectionJSONSchema.
export type RawProcessSelection = z.infer<typeof ProcessSelectionJSONSchema>;

export function processSelectionToGraph(
  s: RawProcessSelection,
): [string | typeof UNNAMED_ENV, ProgramSelection[]] {
  return [
    s.environment == null ? UNNAMED_ENV : s.environment,
    Array.from(s.programsSelection).map(([program, sel]): ProgramSelection => {
      let selectionType: ProgramSelectionType;
      let processTokens: string[] | undefined = undefined;
      switch (sel.type) {
        case "all":
          selectionType = ProgramSelectionType.AllProcesses;
          break;
        case "none":
          selectionType = ProgramSelectionType.NoProcesses;
          break;
        case "some":
          selectionType = ProgramSelectionType.SomeProcesses;
          processTokens = sel.selectedProcs;
          break;
      }
      return {
        program: program,
        type: selectionType,
        processTokens: processTokens,
      };
    }),
  ];
}

// Synchronize the selection status with the report. The new selection is
// returned. selection is not modified in place; a new instance is always
// returned. Returns undefined if the input cannot be reconciled with the
// report, and the report does not have a "default selection".
function syncProcessSelectionWithReport(
  selection: Readonly<RawProcessSelection> | undefined,
  report: ProcessHierarchy | undefined,
): RawProcessSelection | undefined {
  if (report == undefined) {
    return undefined;
  }

  // If the selection status is not initialized, see if we can initialize it
  // from the report.
  if (selection == undefined) {
    return report.defaultSelection();
  }

  // If the environment that we had selected has disappeared, then we reset the
  // selection.
  const env: string | typeof UNNAMED_ENV =
    selection.environment == null ? UNNAMED_ENV : selection.environment;
  if (!report.environments.has(env)) {
    return report.defaultSelection();
  }

  // Make a deep copy of the selection.
  const newSelection: RawProcessSelection = {
    programsSelection: [],
    environment: selection.environment,
  };
  for (const [program, programSelection] of selection.programsSelection) {
    switch (programSelection.type) {
      case "all":
        newSelection.programsSelection.push([program, {type: "all"}]);
        break;
      case "none":
        newSelection.programsSelection.push([program, {type: "none"}]);
        break;
      case "some":
        newSelection.programsSelection.push([
          program,
          {type: "some", selectedProcs: [...programSelection.selectedProcs]},
        ]);
        break;
      default:
        exhaustiveCheck(programSelection);
    }
  }

  const reportEnv = report.environments.get(env)!;

  // Add any new programs to the selection. If previously all programs were
  // fully selected, then any new program will also be fully selected.
  // Otherwise, any new program will not be selected
  let allProgramsSelected = true;
  for (const [_program, processSelection] of newSelection.programsSelection) {
    if (processSelection.type != "all") {
      allProgramsSelected = false;
      break;
    }
  }
  for (const program of reportEnv.programs.keys()) {
    if (
      !newSelection.programsSelection.find(
        ([selectedProgram, _selectionType]) => selectedProgram == program,
      )
    ) {
      newSelection.programsSelection.push([
        program,
        {
          type: allProgramsSelected ? "all" : "none",
        },
      ]);
    }
  }

  // Remove stale programs.
  newSelection.programsSelection = newSelection.programsSelection.filter(
    ([program, _procSelection]) => reportEnv.programs.has(program),
  );

  // Remove any stale processes from the selection.
  // const progSelection = [...selectionStatus.programsSelection];
  newSelection.programsSelection.forEach((_, i) => {
    if (newSelection.programsSelection[i][1].type == "some") {
      const program = newSelection.programsSelection[i][0];
      const procSelection = newSelection.programsSelection[i][1];
      newSelection.programsSelection[i][1].selectedProcs =
        procSelection.selectedProcs.filter((procToken: string) =>
          reportEnv.hasProcess(program, procToken),
        );
    }
  });

  return newSelection;
}

// useProcessSelection reconciles the input selection with the current agent
// report. If there is no report or the selection cannot be reconciled with the
// report and the report doesn't have a default selection, returns undefined for
// the ProcessSelection.
//
// The 3rd return value is a list of binary hashes that correspond to the
// selected processes; it will be an empty array if the selection is undefined.
export function useProcessSelection(
  selection: RawProcessSelection | undefined,
): [ProcessSelection | undefined, AgentReport | undefined, string[]] {
  const agentReports = useContext(AgentReportsContext);
  const procHierarchy: ProcessHierarchy | undefined = agentReports
    ? new ProcessHierarchy(agentReports.Report.Reports)
    : undefined;
  const newSelection: ProcessSelection | undefined =
    ProcessSelection.hydrateFromRaw(selection, procHierarchy);
  const binaries = new Set<string>();
  if (newSelection != undefined) {
    const envProcs = procHierarchy!.environments.get(newSelection.environment)!;
    for (const [program, selection] of newSelection.programsSelection) {
      switch (selection.type) {
        case "all":
          envProcs
            .getBinariesForProgram(program)
            .forEach((b) => binaries.add(b));
          break;
        case "none":
          break;
        case "some":
          for (const pInfo of selection.selectedProcs) {
            const proc = envProcs.getProcess(program, pInfo.processToken);
            if (proc != undefined) {
              binaries.add(proc.binary.hash);
            }
          }
          break;
        default:
          exhaustiveCheck(selection);
      }
    }
  }
  return [newSelection, agentReports, Array.from(binaries)];
}
