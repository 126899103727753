import React from "react";
import {Box, Stack, styled, Typography} from "@mui/material";
import {groupBy} from "lodash";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import {GetRecordingsQuery} from "@graphql/graphql";
import {RecordingTimelineItem} from "./RecordingTimelineItem";

dayjs.extend(isToday);

export type Props = {
  recordings: GetRecordingsQuery["getRecordings"];
};

const DATE_FORMAT_MMM_D_YYYY = "MMM D, YYYY";

export function Timeline({recordings}: Props): React.JSX.Element {
  // Sort the recordings in descending order.
  recordings = [...recordings];
  recordings.sort((a, b) => dayjs(b.startTime).diff(dayjs(a.startTime)));

  const sortedAndGrouped = groupBy(recordings, (recording) => {
    const date = dayjs(recording.startTime);
    if (date.isToday()) {
      return "Today";
    }
    if (date.add(1, "day").isToday()) {
      return "Yesterday";
    }

    return date.format(DATE_FORMAT_MMM_D_YYYY);
  });

  return (
    <Stack sx={{height: "100%"}}>
      {Object.entries(sortedAndGrouped).map(([date, items]) => (
        <Stack direction="row" key={date}>
          <TimeLineBox>
            <Typography
              variant="caption"
              sx={{display: "block", whiteSpace: "break-spaces", width: 50}}
            >
              {date}
            </Typography>
          </TimeLineBox>

          <Stack sx={{flexGrow: 1, mb: 4}} spacing={1}>
            {items.map((recording) => (
              <Box key={recording.id}>
                <RecordingTimelineItem recording={recording} />
              </Box>
            ))}
          </Stack>
        </Stack>
      ))}

      <Stack sx={{flexGrow: 1}}>
        <TimeLineBox />
      </Stack>
    </Stack>
  );
}

const TimeLineBox = styled(Box)(({theme}) => {
  const width = 90;
  const borderWidth = 1;
  const color = theme.palette.info.main;
  const squareSize = 16;

  return {
    borderRight: `${borderWidth}px solid`,
    width: width,
    minWidth: width,
    marginRight: "30px",
    borderColor: color,
    height: "100%",
    position: "relative",

    "&:not(:last-child):after": {
      content: "''",
      position: "absolute",
      width: squareSize,
      height: squareSize,
      top: 0,
      right: `-${squareSize / 2 + borderWidth}px`,
      borderRadius: 4,
      border: `${borderWidth}px solid`,
      borderColor: color,
      backgroundColor: theme.palette.background.default,
    },

    "&:not(:last-child):before": {
      content: "''",
      position: "absolute",
      width: squareSize - 10,
      height: squareSize - 10,
      top: squareSize / 2,
      transform: `translate(-50%, -50%)`,
      right: `-${squareSize / 2 - borderWidth}px`,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
    },
  };
});
