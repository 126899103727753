import {useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {Box, Stack, Typography} from "@mui/material";
import {Dayjs} from "dayjs";
import {computeSearchParams, stateFromURL} from "src/util/url.ts";
import {GET_RECORDINGS, makeRecordingsParamUpdaters} from "./gqlHelper.ts";
import SelectorEnvironment from "../../components/SelectorEnvironment.tsx";
import {Timeline} from "./Timeline.tsx";
import {SelectorDate} from "../../components/SelectorDate.tsx";
import {SelectorProgram} from "../../components/SelectorProgram.tsx";
import {noEnvSymbol} from "src/pages/Agents/components/AllProcesses/AllProcesses.tsx";

export default function RecordingsList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramUpdater = makeRecordingsParamUpdaters();

  const urlState = stateFromURL(searchParams, paramUpdater);
  const envFilter = urlState.env; // null means no selection

  const [dateFilter, setDateFilter] = useState<Dayjs | undefined>(undefined);
  const [programFilter, setProgramFilter] = useState<string | undefined>(
    undefined,
  );

  // Turn the value in the graphql format (i.e. "" for "no environment").
  const noEnvValue = "<no environment>";
  const canonicalEnv = envFilter != noEnvValue ? envFilter : "";

  // undefined means remove the filtering.
  const setEnvironmentFilter = (
    newEnv: string | typeof noEnvSymbol | undefined,
  ) => {
    setSearchParams(
      computeSearchParams(
        searchParams,
        {
          env: newEnv == noEnvSymbol ? noEnvValue : newEnv,
        },
        paramUpdater,
      ),
    );
  };

  // NOTE: I didn't use useSuspenseQuery here because it doesn't seem to play
  // nicely with the DatePicker. With useSuspenseQuery, sometimes the DatePicker
  // doesn't close after selecting a date.
  const {
    data: recordingsRes,
    loading: loadingRecordings,
    error: recordingsErr,
  } = useQuery(GET_RECORDINGS, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        Environment: canonicalEnv,
        Program: programFilter,
        Date: dateFilter?.format("YYYY-MM-DD"),
      },
    },
  });

  if (recordingsErr) {
    throw recordingsErr;
  }

  // Show the loading message if we have no data yet. Otherwise, show the old
  // data while the new data is loading.
  if (loadingRecordings && !recordingsRes) {
    return <div>Loading...</div>;
  }

  const recordings = recordingsRes!.getRecordings;

  return (
    <>
      <Box sx={{my: 3}}>
        <Typography variant="h1">Recordings</Typography>
      </Box>

      <Stack direction="row" gap={2} mb={5} flexWrap="wrap">
        <SelectorEnvironment
          allowEmpty={true}
          selectedEnv={envFilter}
          onChange={setEnvironmentFilter}
        />

        <SelectorProgram state={programFilter} setState={setProgramFilter} />

        {/*TODO: Disable dates without recordings. The difficulty is that, on*/}
        {/*year change, the calendar calls this function for every day in the*/}
        {/*year?*/}
        {/*shouldDisableDate={(newDate) => true}*/}
        <SelectorDate state={dateFilter} setState={setDateFilter} />
      </Stack>

      {recordings.length > 0 ? (
        <Timeline recordings={recordings} />
      ) : (
        <Typography>
          {envFilter != undefined ||
          programFilter != undefined ||
          dateFilter != undefined ? (
            <>No recordings found.</>
          ) : (
            <>
              No recordings were captured. Check out{" "}
              <a href="https://docs.side-eye.io" target={"_blank"}>
                the docs
              </a>{" "}
              to get started.{" "}
            </>
          )}
        </Typography>
      )}
    </>
  );
}
