import {Flamegraph, ParseTreeData, TreeNode} from "src/components/Flamegraph";
import React, {Suspense} from "react";
import {useQuery, useSuspenseQuery} from "@apollo/client";
import {gql} from "src/__generated__";
import {toastError} from "@components/tables/util";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab, Typography} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import {Tables} from "@components/tables/tables";
import {Tables_Kind} from "@graphql/graphql";
import {ProcessResolver} from "@util/process-resolver";
import {NANOS_PER_MILLI_BIGINT, ProcessInfo} from "@util/util";
import {ProcessResolverProvider} from "@providers/processResolverProvider";
import {RecordingProvider} from "@providers/recordingProvider";

const GET_TREE_FOR_CPU_PROFILE = gql(/* GraphQL */ `
  query GetTreeForCPUProfile($profileID: ID!, $filters: [StacksFilter!]) {
    getTreeForCPUProfile(profileID: $profileID, filters: $filters)
  }
`);

const GET_CPU_PROFILE = gql(/* GraphQL */ `
  query GetCpuProfile($id: ID!) {
    getCpuProfile(id: $id) {
      id
      recordingID
      eventLogID
      eventLogStreamID
      processes {
        processID
        binaryID
        processFriendlyName
        captureTime
        duckDBProcessUUID
      }
    }
  }
`);

export default function CPUProfile() {
  const pathParams = useParams();
  const profileID = parseInt(pathParams.profileID!);
  const [tab, setTab] = React.useState("flamegraph");

  const {data: profileRes} = useSuspenseQuery(GET_CPU_PROFILE, {
    variables: {
      id: profileID,
    },
  });

  if (profileRes.getCpuProfile.eventLogID == null) {
    throw new Error("CPU profile does not have an event log");
  }
  if (profileRes.getCpuProfile.eventLogStreamID == null) {
    throw new Error("CPU profile does not have an event log stream");
  }

  const processResolver = new ProcessResolver(
    profileRes.getCpuProfile.processes.map(
      (s): ProcessInfo => ({
        ProcessID: s.processID,
        BinaryID: s.binaryID,
        FriendlyName: s.processFriendlyName,
        CaptureTimeNanos:
          BigInt(Date.parse(s.captureTime)) * NANOS_PER_MILLI_BIGINT,
        DuckDBProcessUUID: s.duckDBProcessUUID,
      }),
    ),
    [] /* binaries */,
  );
  return (
    <RecordingProvider
      value={{recordingID: profileRes.getCpuProfile.recordingID}}
    >
      <ProcessResolverProvider value={processResolver}>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider"}}>
            <TabList onChange={(_ev, value) => setTab(value)}>
              <Tab label="Flame graph" value="flamegraph" />
              <Tab label="Captured data" value="tables" />
            </TabList>
          </Box>
          <TabPanel value="flamegraph">
            <FlameGraphTab profileID={profileID} />
          </TabPanel>
          <TabPanel value="tables">
            <ProfileTables
              logID={profileRes.getCpuProfile.eventLogID}
              streamID={profileRes.getCpuProfile.eventLogStreamID}
            />
          </TabPanel>
        </TabContext>
      </ProcessResolverProvider>
    </RecordingProvider>
  );
}

function FlameGraphTab({profileID}: {profileID: number}): React.JSX.Element {
  const {data, loading, error} = useQuery(GET_TREE_FOR_CPU_PROFILE, {
    variables: {
      profileID,
      // TODO: support filtering
      filters: undefined,
    },
  });
  let flamegraphData: TreeNode | undefined;
  if (error) {
    toastError(error);
  } else if (data) {
    // TODO: Parsing the data might be expensive. We shouldn't do it on every
    // render; we should find a way to memoize it or do it only after a new
    // query has actually been run.
    flamegraphData = ParseTreeData(data.getTreeForCPUProfile);
  }

  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : error ? (
        <>Error: {error.message}</>
      ) : !flamegraphData ? (
        <>No stacks collected</>
      ) : (
        <Box>
          <Flamegraph
            root={flamegraphData}
            unit={"goroutines"}
            // TODO: support filtering and actions
            highlighterFilter={""}
            filters={[]}
            hiddenNodes={[]}
            focusedNodes={[]}
            actions={{
              setSelectedFrame: () => {},
              setHidden: () => {},
              setFocused: () => {},
            }}
          />
        </Box>
      )}
    </>
  );
}

// ProfileTables render the tables generated from the data collected from the
// stack traces of a CPU profile.
function ProfileTables({
  logID,
  streamID,
}: {
  logID: number;
  streamID: number;
}): React.JSX.Element {
  return (
    <ErrorBoundary
      fallbackRender={({error}) => (
        <Box>
          <Typography color={"error"}>
            Failed to generate tables: {error.message}
          </Typography>
        </Box>
      )}
    >
      <Suspense fallback={<div>Generating tables...</div>}>
        <Tables
          logID={logID}
          streamID={streamID}
          tablesKind={Tables_Kind.StackFrames}
        />
      </Suspense>
    </ErrorBoundary>
  );
}
