import React, {useState} from "react";
import {useApolloClient} from "@apollo/client";
import {addOrUpdateFunctionSpec} from "@util/queries.tsx";
import {Button, Stack} from "@mui/material";
import FunctionsAutocomplete from "./FunctionsAutocomplete.tsx";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {FunctionName} from "@graphql/graphql";

// AddFunctionSpecCard renders an autocomplete box that lets the user select a
// function to add to the spec.
export function AddFunctionSpecCard({binaryID}: {binaryID: string}) {
  const [selectedFunc, setSelectedFunc] = useState(null as FunctionName | null);
  // We control the Autocomplete's input value in order to be able to clear the
  // autocomplete.
  const [inputValue, setInputValue] = useState("");
  const client = useApolloClient();

  async function addFunctionToSpec() {
    const ok = await addOrUpdateFunctionSpec(
      client,
      {
        funcQualifiedName: selectedFunc!.QualifiedName,
      },
      undefined /* showConfirmationDialog - no validation needed for empty specs */,
    );
    if (ok) {
      setSelectedFunc(null);
      setInputValue("");
    }
  }

  return (
    <Stack direction="row" gap={2}>
      <FunctionsAutocomplete
        binaryID={binaryID}
        inputValue={inputValue}
        value={selectedFunc}
        onValueChange={(val) => setSelectedFunc(val)}
        onInputChange={(val) => setInputValue(val)}
      />
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddOutlinedIcon />}
        onClick={() => void addFunctionToSpec()}
        disabled={selectedFunc == null}
      >
        Add
      </Button>
    </Stack>
  );
}
