import {PprofAddress} from "../@types";

function isValidURL(address: string): boolean {
  try {
    const url = new URL(address);
    return url.protocol == "http:" || url.protocol == "https:";
  } catch (_) {
    return false;
  }
}

function makePprofAddress(value: string): PprofAddress {
  const asNumber = parseInt(value);
  let url: string | undefined;
  if (!isNaN(asNumber) && asNumber > 0 && asNumber < 64 << 10) {
    url = `http://localhost:${asNumber}/debug/pprof/`;
  } else if (isValidURL(value)) {
    url = value;
  }
  if (url === undefined) {
    return {value: value, error: true};
  }
  return {value: value, url: url, kind: "url"};
}

export class ProcessPprofAddresses {
  readonly enabled: boolean;
  private readonly addresses: Record<string, PprofAddress> = {};
  private readonly updateAddress: (
    processToken: string,
    address: string,
  ) => void;

  constructor(
    enabled: boolean,
    addresses: Record<string, string>,
    updateAddress: (processToken: string, address: string) => void,
  ) {
    this.enabled = enabled;
    this.addresses = Object.fromEntries(
      Object.entries(addresses).map(([processToken, address]) => {
        return [processToken, makePprofAddress(address)];
      }),
    );
    this.updateAddress = updateAddress;
  }

  getProcessAddress(processToken: string): PprofAddress | undefined {
    return this.addresses[processToken];
  }

  setProcessAddress(processToken: string, address: string) {
    this.updateAddress(processToken, address);
  }

  hasValidURLs(): boolean {
    return this.validURLs().length > 0;
  }

  // shouldCollect returns true if execution traces and CPU profiles should be
  // collected as part of a recording.
  shouldCollect(): boolean {
    return this.enabled;
  }

  validURLs(): {processToken: string; url: string}[] {
    return Object.entries(this.addresses)
      .map(([processToken, address]) => {
        if ("url" in address) {
          return {processToken, url: address.url};
        }
        return undefined;
      })
      .filter((a) => a !== undefined);
  }
}
