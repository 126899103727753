import {useQuery} from "@apollo/client";
import React from "react";
import EventFrequencyChart from "@components/EventFrequencyChart";
import {GET_EVENT_FREQUENCY} from "src/pages/EventLog/gql.tsx";
import dayjs from "dayjs"; // LiveHistogram polls the server for a histogram of the latencies of events in

// EventFrequency polls the server for a histogram of the latencies of events in
// a given log.
export default function EventFrequency({
  logID,
  funcQualifiedName,
  lineNumber,
  poll,
}: {
  logID: number;
  funcQualifiedName?: string;
  lineNumber?: number;
  // If set, the histogram will poll the server for updates once a second.
  poll: boolean;
}): React.JSX.Element {
  const {loading, error, data} = useQuery(GET_EVENT_FREQUENCY, {
    variables: {
      id: logID,
      funcQualifiedName: funcQualifiedName,
      lineNumber: lineNumber,
    },
    // Disable polling by setting it to a really high number. Switching from
    // 1000 -> undefined does not work:
    // https://github.com/apollographql/apollo-client/issues/11120
    pollInterval: poll ? 1000 : 100000000,
  });

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>Error: {error.message}</>;
  }
  const series = data!.getEventFrequency.functionCounts;

  // The x-axis simply counts from 0 to the number of seconds in the series. All
  // series have the same length.
  const xData: number[] = [];
  for (let i = 0; i < series[0].countsPerSecond.length; i++) {
    xData.push(i);
  }

  return (
    <EventFrequencyChart
      series={series}
      xAxisData={xData}
      startTime={dayjs(data!.getEventFrequency.start)}
    />
  );
}
