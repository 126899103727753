import React, {createContext, PropsWithChildren, useContext} from "react";

const recordingContext = createContext<RecordingContext | undefined>(undefined);

export const RecordingProvider: React.FC<
  PropsWithChildren & {value: RecordingContext}
> = ({children, value}) => {
  return (
    <recordingContext.Provider value={value}>
      {children}
    </recordingContext.Provider>
  );
};

export const useRecording = () => {
  const recContext = useContext(recordingContext);
  return recContext;
};

export type RecordingContext = {
  recordingID: number;
};
