import React, {useState} from "react";
import {ApolloClient, useApolloClient, useSuspenseQuery} from "@apollo/client";
import {PprofRule} from "@graphql/graphql";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {Subheader} from "./Subheader";
import {
  ADD_PPROF_RULE,
  DELETE_PPROF_RULE,
  GET_PPROF_RULES,
} from "../../gqlHelper";
import {IconEdit} from "@components/icons/IconEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditableLabel from "@util/editable-label";
import {GET_PROGRAMS} from "@util/queries";

export function PprofAddressRules(): React.JSX.Element {
  const {data: programsRes} = useSuspenseQuery(GET_PROGRAMS);

  const programNames = programsRes.getPrograms;

  const {data: rulesRes} = useSuspenseQuery(GET_PPROF_RULES, {});
  const rules = rulesRes.getPprofRules;

  const client = useApolloClient();

  const [addingRule, setAddingRule] = useState(false);

  async function onDeleteRule(id: number) {
    await deletePprofRule(client, id);
  }

  async function onAddRule(program: string, port: number) {
    await addPprofRule(client, program, port);
    setAddingRule(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" gap={2} justifyContent="space-between">
            <span>Pprof address rules</span>
            <Button
              onClick={() => setAddingRule(true)}
              disabled={addingRule}
              variant="contained"
              startIcon={<AddOutlinedIcon />}
            >
              Add new pprof rule
            </Button>
          </Stack>
        }
        subheader={<Subheader />}
      />

      <CardContent component={Stack} gap={3}>
        {rules.map((rule, i) => (
          <React.Fragment key={rule.ID}>
            {!!i && <Divider />}
            <PprofRuleEditor
              rule={rule}
              programs={programNames}
              onDelete={() => onDeleteRule(rule.ID)}
              onSave={async (program, port) => await onAddRule(program, port)}
            />
          </React.Fragment>
        ))}

        {addingRule && (
          <PprofRuleEditor
            programs={programNames}
            onSave={onAddRule}
            onCancel={() => setAddingRule(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}

async function addPprofRule(
  client: ApolloClient<unknown>,
  program: string,
  port: number,
) {
  const {errors} = await client.mutate({
    mutation: ADD_PPROF_RULE,
    variables: {
      program,
      port,
    },
    refetchQueries: [GET_PPROF_RULES],
  });
  return errors;
}

async function deletePprofRule(client: ApolloClient<unknown>, id: number) {
  const {errors} = await client.mutate({
    mutation: DELETE_PPROF_RULE,
    variables: {
      id,
    },
    refetchQueries: [GET_PPROF_RULES],
  });
  return errors;
}

type PprofRuleEditorProps = {
  // The rule to edit. If not set, we're in "add" mode.
  rule?: PprofRule;
  programs: string[];
  onSave: (program: string, port: number) => Promise<void>;
  // onDelete does not have to be specified when adding a new predicate.
  onDelete?: () => Promise<void>;
  // onCancel does not have to be specified when editing an existing predicate
  // (as opposed to adding a new one).
  onCancel?: () => void;
};

function PprofRuleEditor(props: PprofRuleEditorProps): React.JSX.Element {
  // If the rule is not specified, we are in "add" mode. This means we're in
  // edit mode and there's no way to exit edit mode.
  const adding = props.rule == undefined;
  const [editing, setEditing] = useState(adding);

  const [program, setProgram] = useState<string>(props.rule?.program ?? "");
  const [port, setPort] = useState<string>(props.rule?.port.toString() ?? "");

  function onEdit() {
    setEditing(true);
    setPort(props.rule!.port.toString() ?? "");
    setProgram(props.rule!.program ?? "");
  }

  async function onDelete() {
    await props.onDelete!();
  }

  function onCancel() {
    if (props.onCancel) {
      props.onCancel();
    } else {
      setEditing(false);
    }
  }

  async function onSave() {
    const portNum = Number.parseInt(port);
    await props.onSave(program, portNum);
    setEditing(false);
  }

  if (!editing) {
    return (
      <Card color="default">
        <CardHeader
          title={
            <Stack direction="row" gap={1}>
              <Typography variant="body4">Program:</Typography>
              <Typography variant="body4" color="primary.light">
                {props.rule!.program}
              </Typography>

              <Tooltip title={"Edit program rule"} sx={{ml: "auto"}}>
                <Button color="info" variant="outlined" onClick={onEdit}>
                  <IconEdit />
                </Button>
              </Tooltip>
              <Tooltip title={"Delete program rule"}>
                <Button color="info" variant="outlined" onClick={onDelete}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Stack>
          }
        ></CardHeader>

        <CardContent>
          <Typography variant="subtitle1" sx={{mb: 2}}>
            Port: {props.rule!.port}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // We're in editing mode.
  return (
    <Card color="default">
      <CardHeader
        title={
          <Select
            value={program}
            onChange={(e) => setProgram(e.target.value)}
            sx={{
              flexGrow: 1,
              minWidth: 400,
              ".MuiSelect-select": {
                display: "flex",
                gap: "10px",
                alignItems: "center",
              },
            }}
            size="medium"
            color="secondary"
            displayEmpty
          >
            <MenuItem value="" disabled sx={{display: "none"}}>
              <Typography variant="error">Program</Typography>
            </MenuItem>
            {props.programs.map((program, index) => (
              <MenuItem
                key={program}
                value={program}
                sx={{display: "flex", alignItems: "center", gap: 1}}
              >
                {program}
              </MenuItem>
            ))}
          </Select>
        }
      />
      <CardContent>
        <Typography variant="subtitle1" sx={{mb: 2}}>
          Port:
        </Typography>

        <EditableLabel
          text={port}
          allowEmpty={false}
          onSave={async (newPort) => {
            setPort(newPort);
            return true;
          }}
        />

        <Divider sx={{my: 3}} />

        <Stack direction="row" gap={1} justifyContent="flex-end">
          <Tooltip title={"Cancel"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onCancel}
              size="large"
            >
              Cancel
            </Button>
          </Tooltip>

          <Tooltip title={"Save pprof address rule"}>
            <span>
              <Button
                color="primary"
                variant="contained"
                onClick={onSave}
                startIcon={<SaveIcon />}
                size="large"
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </CardContent>
    </Card>
  );
}
